
<app-spinner [show]="showSpinner" ></app-spinner>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div *ngIf="step === 'name'">
        <div class="form-group">
          <div class="form-group">
            <label>Name</label>
            <input name="name" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedNotificationTemplate.name">
          </div>
          <div class="form-group">
            <label>Description</label>
            <input name="description" class="form-control" placeholder="Description" type="text" [(ngModel)]="selectedNotificationTemplate.description">
          </div>
        </div>
      </div>
      <div *ngIf="step === 'message'">
        <div class="form-group">
          <div class="form-group">
              <label>Transport</label>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" >
                  <div class="custom-control custom-checkbox">
                    <input name="email" type="checkbox" class="custom-control-input" id="email" [(ngModel)]="selectedNotificationTemplate.transport.email" >
                    <label class="custom-control-label" for="email">email</label>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="custom-control custom-checkbox">
                    <input name="mqtt" type="checkbox" class="custom-control-input" id="mqtt" [(ngModel)]="selectedNotificationTemplate.transport.mqtt" >
                    <label class="custom-control-label" for="mqtt">mqtt</label>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="custom-control custom-checkbox">
                    <input name="push" type="checkbox" class="custom-control-input" id="pushNotification" [(ngModel)]="selectedNotificationTemplate.transport.pushNotification" >
                    <label class="custom-control-label" for="pushNotification">pushNotification</label>
                  </div>
                </li>
              </ul>
          </div>


          <div class="form-group">
            <ul>
              <li *ngFor="let dataStreamClass of selectedNotificationTemplate.dataStreamClasses;  let i = index; trackBy: trackByFn">
                <input  name="dataStreamClasses{{i}}" class="form-control" placeholder="class Id" type="text" [(ngModel)]="selectedNotificationTemplate.dataStreamClasses[i]"/>
                <button (click)="removeDataClass(i)" >Remove</button>
              </li>
            </ul>
            <button (click)="addDataClass()" >Add</button>
          </div>

          


          <div class="form-group">
            <label>Notification title/subject</label>
            <input name="title" class="form-control" placeholder="Title" type="text" [(ngModel)]="selectedNotificationTemplate.title">
          </div>
          <div class="form-group">
            <label>Notification message</label>
            <input name="message" class="form-control" placeholder="message" type="text"  [(ngModel)]="selectedNotificationTemplate.message">
          </div>
          <div class="form-group">
            <label>Notification Frequency</label>
            <input name="runFrequency" class="form-control" placeholder="* * * * *" type="text"  [(ngModel)]="selectedNotificationTemplate.runFrequency">
          </div>
        </div>
      </div>
      <div *ngIf="step === 'ranges'">
        <div class="form-group">
          <div class="form-group">
            <label>Value Min</label>
            <input name="minValue" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedNotificationTemplate.valueRange.minValue">
          </div>
          <div class="form-group">
            <label>Value Max</label>
            <input name="maxValue" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedNotificationTemplate.valueRange.maxValue">
          </div>
          <div class="form-group">
            <label>Time Range: Min Age (minutes)</label>
            <input name="minAge" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedNotificationTemplate.timeRange.minAge">
          </div>
          <div class="form-group">
            <label>Time Range: Max Age (minutes)</label>
            <input name="maxAge" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedNotificationTemplate.timeRange.maxAge">
          </div>
        </div>
      </div>
      <div *ngIf="step === 'settings'">
        <div class="form-group">
          <div class="form-group">
            <label>timeToLive</label>
            <input name="timeToLive" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedNotificationTemplate.timeToLive">
          </div>
          <div class="form-group">
            <div class="form-group">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" >
                  <div class="custom-control custom-checkbox">
                    <input name="resetOnAcknowledge" type="checkbox" class="custom-control-input" id="group" [(ngModel)]="selectedNotificationTemplate.resetOnAcknowledge" >
                    <label class="custom-control-label" for="group">resetOnAcknowledge</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <label>grouping level (only options are "devices" or "stream" or "none"</label>
            <input name="grouping" class="form-control" placeholder="Grouping" type="text" [(ngModel)]="selectedNotificationTemplate.grouping">
          </div>
          <div class="form-group">
            <label>groupingIdentifier</label>
            <input name="groupingIdentifier" class="form-control" placeholder="Grouping Identifier" type="text" [(ngModel)]="selectedNotificationTemplate.groupingIdentifier">
          </div>
          <div class="form-group">
            <label>email Template File</label>
            <input name="emailTemplate" class="form-control" placeholder="Email Template" type="text" [(ngModel)]="selectedNotificationTemplate.emailTemplate">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" *ngIf="backMap[step]" (click)="step = backMap[step]">Back</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="nextMap[step]" (click)="step = nextMap[step]">Next</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="saveMap[step]" (click)="save()">Save</button>
  </div>
</ng-template>

<div (click)="addTemplate()" style="padding:10px">
  <fa-icon  [icon]="fa.faPlus"></fa-icon>
  Add Notification
</div>

<table class="table table-striped table-hover" hover="true">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">Name</th>
    <th scope="col">Description</th>
    <th scope="col">Run Frequency</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let notificationTemplate of notificationTemplates; index as i" (click)="openTemplate(notificationTemplate)">
    <th scope="row">{{ i + 1 }}</th>
    <td>{{ notificationTemplate.name }}</td>
    <td>{{ notificationTemplate.description }}</td>
    <td>{{ notificationTemplate.runFrequency }}</td>
  </tr>
  </tbody>
</table>