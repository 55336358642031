import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../providers/api/api.service';
import { Router } from '@angular/router';
import { UserService } from '../../providers/user/user.service';
import { ConfigService } from '../../providers/config/config.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  email = '';
  password = '';
  passwordConfirm;
  name;
  showSpinner = false;
  surname;
  config;
  logoUrl;
  showSignUp;
  version = (<any>window).version;

  passwordReset = false;
  passwordEmail;
  passwordToken;

  activateAccount = false;
  activateMessage = '';
  activateToken;
  activateEmail;

  userCanRegister = false;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private configService: ConfigService,
    ) {
      this.showSignUp = false;
      this.logoUrl = this.getLogoUrl();
      this.config = this.configService.getConfig();
      this.handleConfig();
      this.configService.getConfigChangeEmitter().subscribe(config => {
        this.config = config;
        this.handleConfig();
      });

      const pathParams = (window as any).pathParams;
      const pathHash = (window as any).pathHash;
      if(pathHash.indexOf('#/?password=') == 0) {
        this.passwordReset = true;
        const split = pathHash.split('=').pop().split(':');
        this.passwordEmail = split[0];
        this.passwordToken = split[1];
      }
      if(pathHash.indexOf('#/?activate=') == 0) {
        this.activateAccount = true;
        const split = pathHash.split('=').pop().split(':');
        this.activateEmail = split[0];
        this.activateToken = split[1];
        this.activateMessage = 'Activation in progress.';
        this.apiService.register(
          {
            email: this.activateEmail,
            activateToken: this.activateToken,
          }).subscribe(
          (response: any) => {
            this.activateMessage = 'Activation successful.';
            this.showSignUp = false;
            this.showSpinner = false;

            setTimeout(()=>{
              this.activateAccount = false;
            },2000);
          },
          response => {
            this.showSpinner = false;
            this.activateMessage = 'Activation failed. Please contact administrator/';
          });
      }


     }

  handleConfig() {
    if(this.config.userCanRegister) {
      this.userCanRegister = this.config.userCanRegister;
    } else {
      this.userCanRegister = false;
    }
    this.userCanRegister = true;
  }
  ngOnInit() {
    this.tryAutoLoginLocal();
  }

  async tryAutoLoginLocal(){
    const authInfoString =  localStorage.getItem('authInfo');

    if (authInfoString){
      const authInfo = JSON.parse(authInfoString);
      if (authInfo.expire > (Date.now()/1000) )
      {
        this.userService.setToken(authInfo.authToken, this.password);
        this.router.navigate(['/home']);
      } else {
        localStorage.removeItem('authInfo'); // remove if expired
      }
    }
  }

  resetPassword() {
    if ( !this.password || !this.passwordConfirm ) {
      alert('Complete all fields');
      return;
    }

    if (this.password !== this.passwordConfirm) {
      alert('Passwords do not match');
      return;
    }

    const passwordResetObj = {
      email: this.passwordEmail,
      passwordToken: this.passwordToken,
      password: this.password,
    }

    this.apiService.passwordReset(passwordResetObj).subscribe(
      (response: any) => {
        alert('Password changed');
        this.showSpinner = false;
        this.passwordReset = false;
        this.email = this.passwordEmail;
      },
      response => {
        this.showSpinner = false;
        alert('Reset failed');
      });
  }

  forgotPassword() {
    if (!this.email ) {
      alert('No email specified');
      return;
    }
    if (confirm(`Are you sure you want to reset password for ${this.email}?`)) {
      this.showSpinner = true;
      
      this.apiService.passwordRequest({email: this.email}).subscribe(
        (response: any) => {
          this.showSpinner = false;
          alert('Check your inbox for a password reset link.')
        },
        response => {
          this.showSpinner = false;
          alert('Password reset failed');
        });
      // alert(`An email has been sent to ${this.email}. Please follow the steps.`);
    }
  }
  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }
  getLogoUrl() {
    return `assets/login_logo.png?${new Date().getTime()}`;
  }

  toggleSignUp() {
    this.showSignUp = !this.showSignUp;
  }

  signUp() {
    if (!this.email || !this.password || !this.passwordConfirm || !this.name || !this.surname) {
      alert('Complete all fields');
      return;
    }

    if (this.password !== this.passwordConfirm) {
      alert('Passwords do not match');
      return;
    }
    this.showSpinner = true;
    const domain = window.location.hostname;
    this.apiService.register(
      {
        email: this.email,
        password: this.password,
        name: this.name,
        surname: this.surname,
        domain,
      }).subscribe(
      (response: any) => {
        this.showSignUp = false;
        alert('Please check your emails for an activation link');
        this.showSpinner = false;
      },
      response => {
        this.showSpinner = false;
        alert('Signup failed');
      });
  }


  async rememberLoginLocal(email,token,expire){
    const authInfo = {
      'email': email,
      'authToken': token,
      'expire': expire
    }

    localStorage.setItem('authInfo',JSON.stringify(authInfo));
    
    setTimeout(() => {
      localStorage.removeItem('authInfo');
      
    }, (24*60*60*1000)); //24 hours
  }



  async login() {
    if (!this.email || !this.password) {
      alert('Complete all fields');
      return;
    }
    this.showSpinner = true;

    this.apiService.login(this.email, this.password).subscribe(
      (response: any) => {
        this.userService.setToken(response.token, this.password);
        this.rememberLoginLocal(this.email,response.token,this.userService.user.exp);
        this.showSpinner = false;
        this.router.navigate(['/home']);
      },
      response => {
        let errorMessage = '';
        if(response.error && response.error.message) {
          errorMessage = response.error.message;
        }
        this.showSpinner = false;
        alert(`Login failed\n\r${errorMessage}`);
      });
  }
}
