<div style="padding:25px">
  <h3>
    Admin console
  </h3>
</div>
<div style="padding:10px">
  <div class="list-group">
    <a *ngIf="hasRole('fe:admin:customers')" (click)="open('customers')" class="list-group-item list-group-item-action">
      <fa-icon [icon]=fa.faUsers></fa-icon>
      Customers
    </a>
    <a *ngIf="hasRole('fe:admin:users')"  (click)="open('users')" class="list-group-item list-group-item-action" >
      <fa-icon [icon]=fa.faUser></fa-icon>
      Users
    </a>
    <a *ngIf="hasRole('fe:admin:devices')" (click)="open('devices')" class="list-group-item list-group-item-action">
      <fa-icon [icon]=fa.faMobileAlt></fa-icon>
      Devices
    </a>
    <a *ngIf="hasRole('fe:admin:displayTemplates')" (click)="open('displayTemplates')" class="list-group-item list-group-item-action">
      <fa-icon [icon]=fa.faBorderNone></fa-icon>
      Display Templates
    </a>
    <a *ngIf="hasRole('fe:admin:displayTemplates')" (click)="open('displayTemplateGroups')" class="list-group-item list-group-item-action">
      <fa-icon [icon]=fa.faBorderNone></fa-icon>
      Display Template Groups
    </a>
    <a *ngIf="hasRole('fe:admin:notificationTemplates')" (click)="open('notificationTemplates')" class="list-group-item list-group-item-action">
      <fa-icon [icon]=fa.faInbox></fa-icon>
      Notification Templates
    </a>
    <a *ngIf="hasRole('fe:admin:notificationTemplateGroups')" (click)="open('notificationTemplateGroups')" class="list-group-item list-group-item-action">
      <fa-icon [icon]=fa.faEnvelope></fa-icon>
      Notification Template Groups
    </a>
    <a  *ngIf="hasRole('fe:admin:deviceTemplates')" (click)="open('deviceTemplates')" class="list-group-item list-group-item-action disabled">
      <fa-icon [icon]=fa.faBorderNone></fa-icon>
      Device Templates
    </a>
    
    <a  *ngIf="hasRole('fe:admin:dataClasses')" (click)="open('dataClasses')" class="list-group-item list-group-item-action">
      <fa-icon [icon]=fa.faCode></fa-icon>
      Data Classes
    </a>
    <a  *ngIf="hasRole('fe:admin:dataStreams')" (click)="open('dataStreams')" class="list-group-item list-group-item-action disabled">
      <fa-icon [icon]=fa.faEdit></fa-icon>
      Data Streams
    </a>
  </div>
</div>