import { Component, OnInit } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { UserService } from '../../providers/user/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  fa = fa;
  user;
  constructor(private router: Router, private userService: UserService) {
    this.user = userService.getUser();
  }

  ngOnInit() {
  }

  hasRole(role) {
    return this.user && this.user.roles && this.user.roles.indexOf(role) !== -1;
  }

  open(page) {
    if (page === 'customers') {
      this.router.navigate(['/customers']);
    } else if (page === 'displayTemplates') {
      this.router.navigate(['/display-templates']);
    } else if (page === 'devices') {
      this.router.navigate(['devices']);
    } else if (page === 'users') {
      this.router.navigate(['users']);
    } else if (page === 'notificationTemplateGroups') {
      this.router.navigate(['notificationTemplateGroups']);
    } else if (page === 'notificationTemplates') {
      this.router.navigate(['notificationTemplates']);
    } else if (page === 'dataClasses') {
      this.router.navigate(['dataClasses']);
    } else if (page === 'displayTemplateGroups') {
      this.router.navigate(['displayTemplateGroups']);
    } else {
      alert(page + ' not yet implemented.');
    }
  }
}
