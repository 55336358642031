<app-spinner [show]="showSpinner" ></app-spinner>

<!-- Modals Starts here -->
<ng-template #calibrateModal let-modal>
  <div *ngIf="modalType === 'basicCalibrate'" class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Calibrate Stream: {{calibrateStream.displayName}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form >
          <div *ngIf="step === 'startCalibrate'">
            <label>Device ID: {{ device.deviceid }}</label><br>
            <label>Data Stream: {{calibrateStream.displayName}}</label><br>
            <label>Data Stream ID: {{calibrateStream.streamId}}</label><br>
            <label>Last value: </label><br>
            <label>Last value time: </label><br>
            <label>Calibration Method:  </label>
            <select [(ngModel)]="selectedCalibrationFunction" name="calibrationFunctionSelect">
              <option *ngFor="let function of calibrateStream.calibrationFunctions" [ngValue]="function" >{{function.displayName}}</option>
            </select> 
          </div>
          <div *ngIf="step === 'getCalibrateArgs'">
            <form [formGroup]="calibrationArguments">
              <label>Calibration Method:  {{selectedCalibrationFunction.dispalyName}}</label><br>
              <label>Please provide the following calibration values:</label><br>
              <div *ngFor="let arg of selectedCalibrationFunction.arguments">
                <label>{{arg.displayName}}: </label>
                <input class="form-control" type="{{arg.type}}" [formControlName]='arg.name'> 
              </div>
          </form>
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button *ngIf="step === 'startCalibrate'" type="button" class="btn btn-outline-dark" (click)="getCalibrateArgs();">Next</button>
      <button *ngIf="step === 'getCalibrateArgs'" type="button" class="btn btn-outline-dark" (click)="updateBasicCalibration();" [disabled]="!calibrationArguments.valid">Calibrate</button>
    </div>
  </div>
</ng-template>

<!-- mqtt modal starts here -->
<ng-template #addMqttModal let-modal>
  <div *ngIf="modalType === 'mqttModal'" class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">MQTT Topic Stream: {{mqttStream.displayName}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form >
          <div *ngIf="step === 'selectMqttTopic'">
            <label>Device ID: {{ device.deviceid }}</label><br>
            <label>Data Stream: {{mqttStream.displayName}}</label><br>
            <label>Data Stream ID: {{mqttStream.streamId}}</label><br>
            <label>MQTT topic to add:  </label>
            <select [(ngModel)]="selectedMqttTopic" name="mqttTopicSelect">
              <option *ngFor="let topic of user.mqttTopics" [ngValue]="topic" >{{topic}}</option>
            </select> 
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button *ngIf="step === 'selectMqttTopic'" type="button" class="btn btn-outline-dark" (click)="addMqttTopic();" >Add</button>
    </div>
  </div>
</ng-template>

<!-- Page starts here -->
<div *ngIf="device" class="container">
  <div class="propertyContainer">
    <span class="propertyName">Device ID: </span> <span class="propertyValue"> {{ device.deviceid }}</span>
  </div>
  <div class="propertyContainer">
    <span class="propertyName">Device Type (Template): </span> <span class="propertyValue"> {{ device.deviceTemplateId }}</span>
  </div>
  <div class="propertyContainer">
    <span class="propertyName">Device State: </span> <span class="propertyValue"> {{ prettyStateName(device.activationState) }}</span><br>
    <span class="propertyName">Update Device Sate: </span> 
      <select [(ngModel)]="selectedState">
        <option *ngFor="let state of states" [ngValue]="state.name" >{{ prettyStateName(state.name) }}</option>
      </select>  
       <button  *ngIf="!updating" (click)="updateState()">Update State</button>
  </div>
  <div *ngIf="device.flags" class="propertyContainer" >
    <span class="propertyName">EC Enabled: </span> <span class="propertyValue"> {{ device.flags.conductivityFlag }}</span><br>
    <button  *ngIf="!updating" (click)="updateEC()">Switch EC</button>
  </div>
  <div class="propertyContainer">
    <span class="propertyName">Created Timestamp: </span> <span class="propertyValue"> {{ getPrettyDate(device.created_at) }}</span>
  </div>
  <div class="propertyContainer">
    <span class="propertyName">Last Update: </span> <span class="propertyValue"> {{ getPrettyDate(device.updated_at) }}</span>
  </div>
  <div class="propertyContainer">
    <span class="propertyName">Raw Downlink: </span> <span class="propertyValue"> {{ device.sigfoxDownlink }}</span>
  </div>
  <div class="propertyContainer" >
    <span class="propertyName">Current Transmit interval: </span> <span class="propertyValue"> {{ sleepCount }}</span><br>
    <span class="propertyName">Update Transmit interval: </span> 
      <select [(ngModel)]="selectedSleepCount">
        <option *ngFor="let x of sleepCounters" [ngValue]="x" >{{x.label}}</option>
      </select>  
      <button  *ngIf="!updating" (click)="updateSleepCounter()">Update Interval</button>
  </div>
  <div class="propertyContainer">
    <span class="propertyName">Data Streams Enabled: </span> <span class="propertyValue"> {{ device.streamFlag }}</span>
  </div>
  <div class="propertyContainer">
    <button  *ngIf="!updating" (click)="copyV1Calibration()">Copy Calibration from Poolsense app</button>
  </div>
  <div class="propertyContainer">
    <button  *ngIf="!updating" (click)="resetStreams()">Refresh/Renew Data Streams</button>
  </div>
  <div class="propertyContainer">
    <span class="propertyName">Sigfox Link: </span> 
    <span class="propertyValue"><a target="_blank" href="{{ sigfoxDeviceUrl }}" >{{ device.deviceid }} </a> </span>
  </div>

  <div class="propertyContainer" *ngIf="tokenData">
    <span class="propertyName">Token:</span>
    <span class="propertyName">Type:</span> <span class="propertyValue"> {{ tokenData.type }}</span><br>
    <span class="propertyName">Activation Time:</span> <span class="propertyValue"> {{ tokenData.activationTime }}</span><br>
    <span class="propertyName">Contract Term:</span> <span class="propertyValue"> {{ tokenData.contractTerm }}</span><br>
    <span class="propertyName">Calculated Contract end:</span> <span class="propertyValue"> {{ tokenData.contractEnd }}</span><br>
    <span class="propertyName">Days Till Calculated Contract End:</span> <span class="propertyValue"> {{ tokenData.daysTillContractEnd }}</span><br>
    <span class="propertyName">Sigfox: Token End:</span> <span class="propertyValue"> {{ tokenData.tokenEnd }}</span><br>
    <span class="propertyName">Sigfox: Token State:</span> <span class="propertyValue"> {{ tokenData.tokenState }}</span><br>
  </div>

  <!-- streams start here -->
  <table class="stream-table table table-striped table-hover" hover="true">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Data Stream</th>

      <th scope="col">Data Points</th>
      <th scope="col">Update</th>
      <th scope="col">Stream ID</th>
      <th scope="col">mqttTopics</th>
      <th scope="col">Description</th>
      <th scope="col">Get Data</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let stream of streams; index as i" >
      <th scope="row">{{ i + 1 }}</th>
      <td>{{ stream.displayName }}</td>
      <td>
        <ul>
          <li *ngFor="let datapoint of stream.datapoints | keyvalue ;let i = index; trackBy: trackByFn">
            {{datapoint.key}}: <input *ngIf="!stream.calibrationFunctions && !stream.datapoints.calibrationPoints" type="text" [(ngModel)]="stream.datapoints[datapoint.key]">
            <label *ngIf="stream.calibrationFunctions && !stream.datapoints.calibrationPoints">{{ stream.datapoints[datapoint.key] }}</label>
            <ul *ngIf="stream.datapoints.calibrationPoints">
              <li *ngFor="let calibrationPoint of stream.datapoints.calibrationPoints; index as calPoint">
                {{ calibrationPoint.value }}
              </li>
            </ul>
          </li>
        </ul>
      </td>
      <td>
        <button *ngIf="!stream.calibrationFunctions && stream.datapoints" (click)="updateStream(stream)" >Update</button>
        <button  *ngIf="stream.calibrationFunctions" (click)="startManualCalibration(calibrateModal,stream)">Calibrate</button>
      </td>
      <td>{{ stream.streamId }}</td>
      <td>
        <ul>
          <li *ngFor="let topic of stream.mqttTopics;  let i = index; trackBy: trackByFn">
              <button (click)="removeTopic(stream,i)" >Remove {{ stream.mqttTopics[i] }}</button>
          </li>
        </ul>
        <button (click)="selectMqttTopic(addMqttModal,stream)" >Add topic</button>
      </td>
      <td>{{ stream.description }}</td>
      <td> <button (click)="getLastDatapoint(stream)" >Get Last Value</button></td>
    </tr>
    </tbody>
  </table>
</div>
