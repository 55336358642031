import { Component, OnInit, ViewChild , TemplateRef} from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-display-templates',
  templateUrl: './display-templates.component.html',
  styleUrls: ['./display-templates.component.css']
})
export class DisplayTemplatesComponent implements OnInit {

  @ViewChild('content', {static: true})
  private content: TemplateRef<any>;

  fa = fa;
  showSpinner;
  step;
  modalRef;

  imagePath;
  imgURL;
  imgType;
  message = '';

  displayTemplates = [];
  selectedDisplayTemplate;

  modalHeading = 'Add Display Template';
  disableTemplateName = false;

  backMap = {
    photo: 'name',
    measurement: 'photo',
  };
  nextMap = {
    name: 'photo',
    photo: 'measurement',
  };
  saveMap = {
    measurement: true
  };



  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    ) {
      this.getDisplayTemplates();
  }

  getDisplayTemplates() {
    this.showSpinner = true;
    this.apiService.getDisplayTemplates().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.displayTemplates = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get Customers failed');
      });
  }
  ngOnInit() {
  }

  openModal() {
    this.imgURL = undefined;
    this.step = 'name';
    this.modalRef = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'});
  }

  previewImage(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    this.imgType = mimeType.replace('image/', '');
    reader.onload = (event) => {
      this.imgURL = reader.result;
    };
  }

  addTemplate() {
    this.modalHeading = 'Add Display Template';
    this.disableTemplateName = false;
    this.selectedDisplayTemplate = {
      templateName : '',
      templateDescription : '',
      templateMeasurementString : '',
    };
    this.openModal();
  }

  uploadImage() {
    const imageData = this.imgURL.slice(this.imgURL.indexOf(',') + 1 );
    return new Promise((resolve, reject) => {
      this.apiService.uploadImage(
        {
          type: this.imgType,
          path: `displayTemplates/${this.selectedDisplayTemplate.templateName}/logo`,
          name: 'logo',
          image: imageData,
        }).subscribe(
        (response: any) => {
          if (response.url) {
            return resolve(response.url);
          } else {
            alert('image upload failed');
            return reject();
          }
        },
        response => {
          alert('image upload failed');
          return reject();
        });
    });

  }
  async uploadDisplayTemplate() {
    this.showSpinner = true;
    // let email = this.userService.getUser().email;
    let imageName;
    if (this.imgURL) {
      try {
        imageName = await this.uploadImage();
      } catch (err) {
        alert(`image uplaod failed: ${err}`);
        return;
      }
    }

    const postBody: any = {
      // created_by: email,
      templateName: this.selectedDisplayTemplate.templateName,
      templateDescription: this.selectedDisplayTemplate.templateDescription,
      templateMeasurement: this.selectedDisplayTemplate.templateMeasurement,
      photo: this.selectedDisplayTemplate.photo,
    };

    if (imageName) {
      postBody.photo = imageName;
    }

    this.apiService.postData('Proautomation-Display-Template', postBody).subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.successful > 0) {
          this.imgURL = undefined;
          this.modalRef.close();
          this.getDisplayTemplates();
        } else {
          alert('Register failed');
        }
      },
      response => {
        this.showSpinner = false;
        alert('Register failed ' + response);
      });
  }

  openTemplate(displayTemplate) {
    this.disableTemplateName = true;
    this.modalHeading = 'Update Display Template';
    this.selectedDisplayTemplate = JSON.parse(JSON.stringify(displayTemplate));
    this.selectedDisplayTemplate.templateMeasurementString = JSON.stringify(this.selectedDisplayTemplate.templateMeasurement, null, 2);
    this.openModal();
  }

  save() {
    try {
      this.selectedDisplayTemplate.templateMeasurement = JSON.parse(this.selectedDisplayTemplate.templateMeasurementString);
    } catch (err) {
      alert('Teamplate Measurement not valid');
      return;
    }
    this.uploadDisplayTemplate();
  }

  measurementChange(event) {
    console.log('measurementChange');
    // console.log(this.selectedDisplayTemplate.templateMeasurement);
  }
}
