import { Component, OnInit, ViewChild , TemplateRef} from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-notification-templates',
  templateUrl: './notification-templates.component.html',
  styleUrls: ['./notification-templates.component.css']
})
export class NotificationTemplatesComponent implements OnInit {

  @ViewChild('content', {static: true})
  private content: TemplateRef<any>;

  fa = fa;
  showSpinner;
  step;
  modalRef;

  message = '';

  notificationTemplates = [];
  selectedNotificationTemplate;

  modalHeading = 'Add Notification Template';

  backMap = {
    message: 'name',
    ranges: 'message',
    settings: 'ranges',
  };
  nextMap = {
    name: 'message',
    message: 'ranges',
    ranges: 'settings',
  };
  saveMap = {
    settings: true
  };

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    ) {
      this.getNotificationTemplates();
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getNotificationTemplates() {
    this.showSpinner = true;
    this.apiService.getNotificationTemplates().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.notificationTemplates = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get Customers failed');
      });
  }
  ngOnInit() {
  }

  openModal() {
    this.step = 'name';
    this.modalRef = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'});
  }

  trackByFn(index,) {
    return index;  
  }

  removeDataClass(index) {
    this.selectedNotificationTemplate.dataStreamClasses.splice(index, 1);
  }

  addDataClass() {
    if(!this.selectedNotificationTemplate.dataStreamClasses){
      this.selectedNotificationTemplate.dataStreamClasses = [];
    }
    this.selectedNotificationTemplate.dataStreamClasses.push('');
  }

  addTemplate() {
    this.modalHeading = 'Add Notification Template';
    this.selectedNotificationTemplate = {
      notificationTemplateId: this.uuidv4(),
      name : '',
      description : '',
      transport : {},
      dataStreamClasses: [],
      title : '',
      message : '',
      runFrequency : '* * * * *',
      valueRange : {
        minValue: 0,
        maxValue: 0,
      },
      timeRange: {
        minAge: 0,
        maxAge: 0,
      },
      timeToLive: 0,
      resetOnAcknowledge: false,
      grouping: '',
      groupingIdentifier: '',
      emailTemplate: '',
    };
    this.openModal();
  }

  async uploadNotificationTemplate() {
    this.selectedNotificationTemplate.valueRange.minValue = parseFloat(this.selectedNotificationTemplate.valueRange.minValue);
    this.selectedNotificationTemplate.valueRange.maxValue = parseFloat(this.selectedNotificationTemplate.valueRange.maxValue);
    this.selectedNotificationTemplate.timeRange.minAge = parseFloat(this.selectedNotificationTemplate.timeRange.minAge);
    this.selectedNotificationTemplate.timeRange.maxAge = parseFloat(this.selectedNotificationTemplate.timeRange.maxAge);

    if(isNaN(this.selectedNotificationTemplate.valueRange.minValue)){
      alert('minValue: not a valid number');
      return;
    }
    if(isNaN(this.selectedNotificationTemplate.valueRange.maxValue)){
      alert('maxValue: not a valid number');
      return;
    }
    if(isNaN(this.selectedNotificationTemplate.timeRange.minAge)){
      alert('minAge: not a valid number');
      return;
    }
    if(isNaN(this.selectedNotificationTemplate.timeRange.maxAge)){
      alert('maxAge: not a valid number');
      return;
    }

    this.showSpinner = true;

    for(let i in this.selectedNotificationTemplate){
      if (this.selectedNotificationTemplate[i] === '') {
        delete this.selectedNotificationTemplate[i];
      }
    }

    this.apiService.postData('Proautomation-Notification-Template', this.selectedNotificationTemplate).subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.successful >= 1) {
          this.modalRef.close();
          this.getNotificationTemplates();
        } else {
          alert('Post failed');
        }
      },
      response => {
        this.showSpinner = false;
        alert('Post failed ' + response);
      });
  }

  openTemplate(notificationTemplate) {
    this.modalHeading = 'Update Notification Template';
    this.selectedNotificationTemplate = JSON.parse(JSON.stringify(notificationTemplate));
    this.openModal();
  }

  save() {
    this.uploadNotificationTemplate();
  }
}
