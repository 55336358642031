import { Injectable, EventEmitter } from '@angular/core';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  user;
  public change =  new EventEmitter();
  constructor() { }
  public getUser() {
    return this.user;
  }

  public setUser(user) {
    this.user = user;
    this.change.emit(this.user);
  }

  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }

  public setToken(token, password) {
    const tokenInfo = this.getDecodedAccessToken(token); // decode token
    if (tokenInfo.exp < new Date().getTime()) {
      // console.log('Token expired');
    }
    const userObj = tokenInfo.jwtContent;
    userObj.exp = tokenInfo.exp;
    userObj.iat = tokenInfo.iat;
    userObj.token = token;

    userObj.password = password;
    this.setUser(userObj);
  }

  public logout(){
    localStorage.clear();
    this.setUser({});
    
  }
}
