<app-spinner [show]="showSpinner" ></app-spinner>
<div >
  <label>Filter:</label>
  <input class="form-control filter" type="text" [(ngModel)]="filterString" (ngModelChange)="filterDevices()">
</div>

<table class="table table-striped table-hover" hover="true">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">deviceId</th>
    <th scope="col">deviceTemplateId</th>
    <th scope="col">streams</th>
    <th scope="col">sigfoxDownlink</th>
    <th scope="col">createdAt</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let device of filteredDevices; index as i" (click)="openDevice(device)">
    <th scope="row">{{ i + 1 }}</th>
    <td>{{ device.deviceid }}</td>
    <td>{{ device.deviceTemplateId }}</td>
    <td>
      <ul>
        <li *ngFor="let stream of device.streams">
          {{stream}}
        </li>
      </ul>
    </td>
    <td>{{ device.sigfoxDownlink }}</td>
    <td>{{ getPrettyDate( device.createdAt) }}</td>
  </tr>
  </tbody>
</table>