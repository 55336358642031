import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  showSpinner = false;
  users = [];

  constructor(private apiService: ApiService,
    private router: Router,
    ) {
    this.showSpinner = true;
    this.getUsers();
   }

  ngOnInit() {

  }
  getPrettyDate(epoch) {
    return new Date(epoch).toLocaleString();
  }
  getUsers() {
    this.apiService.getUsers().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.users = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get Users failed');
      });
  }


  openUser(user) {
    if(user.found === false) {
      alert(`Can not open ${user.email}.`);
      return;
    }
    this.router.navigate(['/user',user.email]);
  }

}
