import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // baseUrl = 'https://api.poolsense.net/api';
  // baseUrl = 'http://127.0.0.1:3000';
  //baseUrl = 'http://localhost:3000';
  //baseUrl = 'https://stg-api.proa.live';
  baseUrl = environment.baseUrl;
  user;
  domainTemplates = '';
  constructor(private httpClient: HttpClient, private userService: UserService) {
  }

  public getHeaders() {
    let headers = new HttpHeaders()
      .set('X-API-KEY', '6w0zvEJY4f9bdi9t31KWe4vSjXSmk53sPN1noUlh');
    this.user = this.userService.getUser();
    if (this.user && this.user.token) {
      headers = headers.append('x-token', this.user.token);
    }
    return { headers };
  }
  public login(email, password) {
    return this.httpClient.post(`${this.baseUrl}/v2/users/login`, { email, password }, this.getHeaders());
  }


  public loginRefresh(email) {
    return this.httpClient.post(`${this.baseUrl}/v2/users/login`, { email }, this.getHeaders());
  }

  public register(object) {
    return this.httpClient.post(`${this.baseUrl}/v2/users/register`, object, this.getHeaders());
  }

  public postStreamData(object) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/post/stream`, object, this.getHeaders());
  }

  public syncCalibrateData(deviceid) {
    return this.httpClient.post(`${this.baseUrl}/v2/device/syncCalibrateData/${deviceid}`, {}, this.getHeaders());
  }

  public refreshDeviceStreams(deviceid) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/refreshDeviceStreams/${deviceid}`, {}, this.getHeaders());
  }

  public streamCalibrate(object){
    return this.httpClient.post(`${this.baseUrl}/v2/data/streamCalibrate/`, object  , this.getHeaders());
  }

  public postComment(object) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/upsert/Proautomation-Data-Point`, object, this.getHeaders());
  }

  public postData(table, object) {
    this.user = this.userService.getUser();
    object.updated_by = this.user.email;
    object.timestamp = Date.now();
    return this.httpClient.post(`${this.baseUrl}/v2/data/upsert/${table}`, object, this.getHeaders());
  }

  public registerDevice(object) {
    this.user = this.userService.getUser();
    object.updated_by = this.user.email;
    object.timestamp = Date.now();
    return this.httpClient.post(`${this.baseUrl}/v2/users/registerDevice`, object, this.getHeaders());
  }



  public updateTempalteGroup(object){
    return this.postData('Proautomation-Display-Template-Group',object);
  }

  public updateDevice(object) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/upsert/Poolsense-Device`, object, this.getHeaders());
  }

  public uploadImage(object) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/uploadImage`, object, this.getHeaders());
  }

  public getRegistrations(index) {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Poolsense-User-Device-Registrations?index=${index}`, this.getHeaders());
  }

  public getCustomers() {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Customer-Portal`, this.getHeaders());
  }

  public getUsers() {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Poolsense-Users`, this.getHeaders());
  }

  public getClasses() {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Data-Class`, this.getHeaders());
  }
  

  public getUser(email) {
    let uri = encodeURI(`${this.baseUrl}/v2/data/get/Poolsense-Users?index=${email}`)
    return this.httpClient.get(uri, this.getHeaders());
  }

  public getClass(dataClassId) {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Data-Class?index=${dataClassId}`, this.getHeaders());
  }

  public updateUser(object) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/upsert/Poolsense-Users`, object, this.getHeaders());
  }

  public updateClass(object) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/upsert/Proautomation-Data-Class`, object, this.getHeaders());
  }

  public getDevices() {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Poolsense-Device`, this.getHeaders());
  }

  public getDataPoints(streams, filter, limit?) {
    let url = `${this.baseUrl}/v2/data/get/Proautomation-Data-Point?index=${streams}&filter=${filter}`;
    if (limit) {
      url = `${url}&limit=${limit}`;
    }
    return this.httpClient.get(url, this.getHeaders());
  }

  public postStream(stream) {
    return this.httpClient.post(`${this.baseUrl}/v2/data/upsert/Proautomation-Data-Stream`, stream, this.getHeaders());
  }

  public updatePush(body) {
    return this.httpClient.post(`${this.baseUrl}/v2/users/updatePush`, body, this.getHeaders());
  }

  public passwordRequest(body) {
    return this.httpClient.post(`${this.baseUrl}/v2/users/passwordRequest`, body, this.getHeaders());
  }

  public passwordReset(body) {
    return this.httpClient.post(`${this.baseUrl}/v2/users/passwordReset`, body, this.getHeaders());
  }

  public getStreams(streams) {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Data-Stream?index=${streams}`, this.getHeaders());
  }

  public getDevice(deviceId) {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Poolsense-Device?index=${deviceId}`, this.getHeaders());
  }

  public getDisplayTemplates(index?) {
    if (index === undefined) {
      return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Display-Template`, this.getHeaders());
    }
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Display-Template?index=${index}`, this.getHeaders());
  }

  public getDisplayTemplateGroup(index?) {
    if (index === undefined) {
      return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Display-Template-Group`, this.getHeaders());
    }
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Display-Template-Group?index=${index}`, this.getHeaders());
  }

  public getNotificationTemplates() {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Notification-Template`, this.getHeaders());
  }

  public getNotificationTemplateGroups(index?) {
    if (index === undefined) {
      return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Notification-Template-Group`, this.getHeaders());
    }
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Notification-Template-Group?index=${index}`, this.getHeaders());
  }

  //get all notificaitons for a specific user

  public getNotifications(user, filter, limit?) {
    let url = `${this.baseUrl}/v2/data/get/Proautomation-Notification?index=${user}&filter=${filter}`;
    if (limit) {
      url = `${url}&limit=${limit}`;
    }
    return this.httpClient.get(url, this.getHeaders());
  }


  public getImage(serial, email, postfix) {
    const encodedUrl = encodeURI(`${serial}_${email}`);
    return this.httpClient.get(`https://poolsense-app-images.s3.amazonaws.com/${encodedUrl}${postfix}`, { responseType: 'text' });
  }

  public getSiteConfigFile() {
    return this.httpClient.get(`/assets/site-config.json`);
  }

  public getSiteConfig(domain) {
    return this.httpClient.get(`${this.baseUrl}/v2/data/get/Proautomation-Customer-Portal?index=${domain}`, this.getHeaders());
  }

  public getDefaultTemplate() {
    return this.httpClient.get(`/assets/default-template.json`);
  }
}
