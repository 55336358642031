import { Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef, ViewChildren} from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../providers/api/api.service';
import { UserService } from '../../providers/user/user.service';

import { ChartDataset, ChartOptions, ChartType, Chart } from 'chart.js';
// import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-adapter-moment';
// import { CompileShallowModuleMetadata, debugOutputAstAsTypeScript } from '@angular/compiler';

import { trigger, style, animate, transition } from '@angular/animations';
declare const GaugeChart: any;

@Component({
  selector: 'device',
  animations: [
    trigger(
      'myAnimation',
      [
        transition(
        ':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', 'opacity': 1}))
        ]
      ),
      transition(
        ':leave', [
          style({transform: 'translateX(0)', 'opacity': 1}),
          animate('500ms', style({transform: 'translateX(100%)', 'opacity': 0})), 
        ]
      )]
    )
  ],
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {
  fa = fa;
  show:boolean = false;
  modalRef;
  that = this;
  @Input() canEditDevice;
  @Input() device;
  @Input() loading;
  @Input() reloadDevice;
  @Output() editDeviceCallback: any = new EventEmitter();
  @Output() viewNotificationCallback: any = new EventEmitter();
  @Output() postDataCallback: any = new EventEmitter();
  @Output() addCommentCallback: any = new EventEmitter();

  @ViewChild('myChart') myChart: BaseChartDirective;

  chart: any;    // Array to store all my charts

  reloadEvent: EventEmitter<any> = new EventEmitter();
  subscription;

  currentMeasurement;
  modalHeading = 'Post Data';
  modalValue = 0;
  comments = {};
  type = 'bar';
  loadingGraph = false;

  graphTimeFrame = 'week';

  barChartOptions = {
    legend: {
      display: false
   },

    events: [
      'mousemove',
      'mouseout',
      'click',
      'touchstart',
      'touchmove'
    ],
    plugins: {
      tooltip: {
        mode: 'nearest',
        filter(tooltipItem) {
          return tooltipItem.datasetIndex === 0;
        },
        intersect: false,
        callbacks: {
          afterFooter: function(tooltipItem) {
            if (tooltipItem.length > 0) {
              return this.device.streamData[this.device.serial + '_' + this.lastGraphMeasurementClass + '_' + this.graphTimeFrame][tooltipItem[0].index]?.comment;
            }
            return '';

          }.bind(this),
          footer: function(tooltipItem) {
            if (tooltipItem.length > 0 &&
              this.device.streamData[this.device.serial + '_' + this.lastGraphMeasurementClass+ '_' + this.graphTimeFrame][tooltipItem[0].index]?.comment) {
              return 'Comment:';
            }
            return '';
          }.bind(this),
        },
      },
    },
    onClick: (event, element) => {
      event.stopPropagation();
      event.preventDefault();
      const activeElement = element[0];
      const data = activeElement._chart.data;
      const barIndex = activeElement._index;
      const xLabel = data.labels[barIndex];
      const datasetIndex = activeElement._datasetIndex;
      const value = data.datasets[datasetIndex].data[barIndex];
      // const datasetLabel = data.datasets[datasetIndex].label;
      let comments = [];
      if (this.device.streamData[this.device.serial + '_' + this.lastGraphMeasurementClass+ '_' + this.graphTimeFrame][element[0]._index].comments) {
        comments = this.device.streamData[this.device.serial + '_' + this.lastGraphMeasurementClass+ '_' + this.graphTimeFrame][element[0]._index].comments;
      }
      this.addCommentCallback.emit({
        deviceId: this.device.serial,
        measurementClass: this.lastGraphMeasurementClass,
        timestamp: xLabel.getTime(),
        value,
        comments
      });
   },
    elements: {
      point: {
          radius: 0
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    fill: false,

    scales: {
      xAxis:
        {
          type: 'time',
          time: {
            unit: this.graphTimeFrame === 'day' ? 'hour' : 'day',
            unitStepSize: 1,
            displayFormats: {
              'day': 'MMM DD',
              'hour': 'HH:mm',
            }
          },
          gridLines: {
            z: 1,
          }
        },
    },
  };

  public barChartLabels = [];
  public barChartType = 'line';
  public barChartLegend = false;

  public barChartData: any = [
    {data: [], label: ''},
  ];

  constructor(private userService: UserService, 
              private apiService: ApiService, 
              private modalService: NgbModal, 
              ) {
    this.chart = [];
  }

  public lastGraphMeasurementClass = false;
  public lastMeasurement = false;
  public graphMessage = '';
  public graphMessageColor = '';

  loadTiles() {

    this.device.template.templateMeasurement.forEach((measurement) => {
      if(measurement.tileType && measurement.tileType === 'gauge'){
        this.loadGauge(measurement);
      } else {
      }
    })
  }

  loadGauge(measurement) {
    const target = document.getElementById(measurement.intersection); // your canvas element
    if(measurement.gauge) {
      if(target && target.offsetHeight > 0) {
        return;
      }
    }
    let value = this.getIntValue(measurement,this.device.lastData,Infinity);
    if(value === Infinity){
      return;
    }

    if (target) {
      let options = {
        needleStartValue:0,
        hasNeedle: true,
        needleColor: 'black',
        arcDelimiters: [],
        arcPadding: 6,
        arcPaddingColor: '#f4f4f4',
        arcLabels: [],
        arcLabelFontSize: 10,
        arcColors: [],
        rangeLabel: [],
        centralLabel: '0',
        rangeLabelFontSize: false,
        // labelsFont: 'Consolas',
        outerNeedle: false,
      }

      let max = 0;
      let min = Infinity;
      let splitPoints = [];

      if (measurement.thresholds) {
        measurement.thresholds.forEach((threshold) => {
          if (max < threshold.range.max) {
            max = threshold.range.max;
          }
          if (min > threshold.range.min) {
            min = threshold.range.min;
          }
          options.arcLabels.push(threshold.range.max);
          options.arcColors.push(threshold.tileColor);
          splitPoints.push(threshold.range.min);
        })
      }
      splitPoints.push(max);

      if(measurement.hasOwnProperty('max') && value > measurement.max) {
        options.centralLabel = measurement.maxLabel;
        value = max;
      } else if(measurement.hasOwnProperty('min') && value < measurement.min) {
        options.centralLabel = measurement.minLabel;
        value = min;
      } else {
        options.centralLabel = this.getValue(measurement,this.device.lastData);
        if (value < min) {
          value = min;
        }
        if (value > max) {
          value = max;
        }
      }

      options.rangeLabel = [min + '',max + ''];
      let needleValue = 0
      if(splitPoints.length > 1){
        const range = splitPoints[splitPoints.length - 1] - splitPoints[0];
        needleValue = this.roundVal(((value - splitPoints[0])/ range)  * 100);
        for(let i = 1 ; i < splitPoints.length - 1; i++) {
          let percentage = this.roundVal(((splitPoints[i] - splitPoints[0] )/ range) * 100);
          if( percentage === 100 ) {
            percentage = 99;
          }
          if( percentage === 0 ) {
            percentage = 1;
          }
          options.arcDelimiters.push(percentage);
        }
      }

      measurement.gauge = GaugeChart.gaugeChart(target, 150, options)
      measurement.gauge.updateNeedle(needleValue)
    }
  }
  ngOnChanges() {
    if(this.loading) {
      return;
    }
    setTimeout(()=>{
      this.loadTiles();
    },100);
  }

  ngOnInit() {
  }

  getMessageColor(){
    if(this.lastMeasurement && this.lastGraphMeasurementClass) {
      return {
        'font-weight': 'bold',
        'border-radius': '10px',
        'background-color': this.graphMessageColor,
      };
    }
    return {};
  }

  getTileColor(measurement, lastData) {
    let boxShadow = 'none';

    if (this.device.showChart) {
      if (this.device.chartType === measurement.type) {
        boxShadow = '0 0 10px 5px #999999';
      }
    }

    return {
      'box-shadow': boxShadow,
      'background-color': '#f4f4f4',//this.getColorFromThreashold(measurement, lastData),
    };
  }

  roundVal(nbr, decimal = 0) {
    const power = 10 ** decimal;
    return Math.round(nbr * power) / power;
  }

  getIntValue(measurement, lastData, defaultValue = 0) {
    if (lastData && lastData[measurement.intersection] !== undefined && lastData[measurement.intersection] !== null) {
      let value = lastData[measurement.intersection];
      if (measurement.hasOwnProperty('round')) {
        value = this.roundVal(lastData[measurement.intersection], measurement.round);
      }
      return value;
    }
    return defaultValue;
  }

  getValue(measurement, lastData) {
    if (lastData && lastData[measurement.intersection] !== undefined && lastData[measurement.intersection] !== null) {
      let value = lastData[measurement.intersection];
      if(measurement.hasOwnProperty('max') && value > measurement.max) {
        return measurement.maxLabel;
      } else if(measurement.hasOwnProperty('min') && value < measurement.min) {
        return measurement.minLabel;
      } else {
        if (measurement.hasOwnProperty('round')) {
          value = this.roundVal(lastData[measurement.intersection], measurement.round);
        }
        if (measurement.thresholds) {
          measurement.thresholds.forEach((threshold) => {
            if (threshold.label && value >= threshold.range.min  && value <= threshold.range.max ) {
              value = threshold.label;
            }
          });
        }
  
  
        return `${value} ${measurement.unit ? measurement.unit : ''}`;
      }
    }
    return 'No Data';
  }

  setTimeFrame(event, timeframe) {
    event.stopPropagation();
    event.preventDefault();
    this.graphTimeFrame = timeframe;
    this.barChartOptions.scales.xAxis.time.unit = this.graphTimeFrame === 'day' ? 'hour' : 'day';
    this.showChart(event, this.device, this.lastMeasurement, true);
  }

  public reloadGraphData() {
    const device = this.device;
    const measurementClass = this.lastGraphMeasurementClass;
    const measurement: any = this.lastMeasurement;

    const data = [];

    const labels = [];
    const commentsData = [];

    this.comments = {};
    let hasComments = false;
    this.lastGraphMeasurementClass = measurementClass;
    let min = false;
    let max = false;

    const streamData = device.streamData[device.serial + '_' + measurementClass + '_' + this.graphTimeFrame];

    this.graphMessage = this.getMessageFromThreashold(measurement, this.device.lastData)
    this.graphMessageColor = this.getColorFromThreashold(measurement, this.device.lastData);

    streamData.forEach((dataSet) => {

      if(measurement.hasOwnProperty('max') && measurement.max < dataSet.value) {
        dataSet.value = measurement.max;
      } else if(measurement.hasOwnProperty('min') && measurement.min > dataSet.value) {
        dataSet.value = measurement.min;
      }

      data.push(dataSet.value);
      if (min === false || min > dataSet.value) {
        min = dataSet.value;
      }
      if (max === false || max < dataSet.value) {
        max = dataSet.value;
      }
      const dataPointDate = new Date(dataSet.timestamp)
      labels.push(dataPointDate.toISOString());

      if (dataSet.comments && dataSet.comments.length > 0) {
        let commentString = '';
        dataSet.comments.forEach((comment) => {
          const timestamp = new Date(comment.timestamp).toISOString();
          const timeString = timestamp.substr(11,5) + ' ' + timestamp.substr(0,10).replace(/-/g, '/');
          commentString = `${commentString}[${timeString}] ${comment.user}: ${comment.comment}\n`;
        })
        commentString = commentString.slice(0,-1);
        dataSet.comment = commentString;
        this.comments[dataSet.timestamp] = commentString;
        commentsData.push(dataSet.value);
        hasComments = true;
      } else {
        commentsData.push(null);
      }
    });

    this.barChartLabels = labels;

    this.barChartData = [];
    let lines = [];
    if (measurement.thresholds &&  measurement.thresholds.length > 0) {
      this.barChartData.push({
        label: measurement.name,
        data,
        fill: 'end',
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: 'white',
      });
      let fallbackLine;
      measurement.thresholds.forEach((threshold) => {
        if (threshold.chart.show) {
          if (threshold.range.max > min) {
            if (threshold.range.min < max) {
              const lineMin = {
                y: threshold.range.min,
                color: threshold.chart.color,
              };
              lines.push(lineMin);
            } else if (threshold.range.max < max) {
              const lineMax = {
                y: threshold.range.max,
                color: 'white',
              };
              lines.push(lineMax);
            } else {
              if (!fallbackLine || fallbackLine.y > threshold.range.max) {
                fallbackLine = {
                  y: threshold.range.max,
                  color: threshold.chart.color,
                };
              }
            }
          }
        }
      });

      if (lines.length == 0) {
        lines.push(fallbackLine);
      }

      lines = lines.sort((a, b) => (a.y < b.y) ? 1 : ((b.y < a.y) ? -1 : 0 ));
      if (lines.length > 0) {
        lines[lines.length - 1].y = min;

        for (const i in lines) {
          if (i) {
            this.barChartData.push(
              {
                label: 'LINE' + i,
                pointRadius: 0,
                pointHitRadius: 0,
                data: new Array(streamData.length).fill(lines[i].y),
                fill: '-' + (parseInt(i, 10) + 1),
                backgroundColor: lines[i].color,
                borderColor: 'transparent',
              }
            );
          }
        }

      } else {
        this.barChartData = [{
          label: measurement.name,
          data,
          fill: true,
          backgroundColor: measurement.defaultColor || '#f4f4f4',
          borderColor: measurement.defaultColor || '#f4f4f4',
        }];
      }
      
    } else {
      this.barChartData = [{
        label: measurement.name,
        data,
        fill: true,
        backgroundColor: measurement.defaultColor || '#f4f4f4',
        borderColor: measurement.defaultColor || '#f4f4f4',
      }];
    }


    if (hasComments) {
      this.barChartData.push({
        label: 'Comments',
        data: commentsData,
        fill: true,
        tooltip: false,
        pointRadius: 10,
        showLine: false,
        backgroundColor: 'transparent',
        borderColor: 'blue',
      });
    }

    device.chartData = {
      labels,
      datasets: [
        {
          label: measurement.name,
          data,
          fill: true,
          // backgroundColor: 'red',
          borderColor: this.getColorFromThreashold(measurement, device.lastData),
        }
      ]
    };
  }

  async showChart(event, device, measurement, keepOpen = false) {
    event.stopPropagation();
    if (measurement.clickable) {

      if (measurement.showChart && !keepOpen) {
        measurement.showChart = false;
      } else {
        measurement.showChart = true;
      }

      if (device.showChart && device.chartType === measurement.type && !keepOpen) {
        device.showChart = false;
        device.chartData = [];
        this.lastGraphMeasurementClass = false;
        this.graphMessage = '';
      } else {
        device.chartType = measurement.type;
        device.showChart = true;
        device.chartData = [];

        let measurementClass = measurement.graphIntersection;

        if (!measurementClass) {
          return;
        }

        if(!device.streamData[measurementClass + '_' + this.graphTimeFrame]){
          this.loadingGraph = true;
          device.streamData[measurementClass + '_' + this.graphTimeFrame] = await this.getDeviceData(measurementClass);
          this.loadingGraph = false;
        }
        this.lastMeasurement = measurement;
        this.lastGraphMeasurementClass = measurementClass.replace(`${device.serial}_`,'');
        this.reloadGraphData();
      }
      if(!keepOpen) {
        setTimeout(() => {
          if (this.myChart) {
            (this.myChart as any).element.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
          }
        },300);
      }
    } else {
      device.showChart = false;
      device.chartData = [];
    }
  }

  getDeviceData(streamsString, limit?) {
    // let streamsString = '';
    // streams.forEach(stream => {
    //   streamsString += ',' + stream;
    // });
    // streamsString = streamsString.replace(',', '');
    return new Promise((resolve) => {
      const date30DaysAgo = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
      const date7DaysAgo = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
      const date1DaysAgo = new Date().getTime() - (24 * 60 * 60 * 1000);

      let timeframe = date7DaysAgo;
      if (this.graphTimeFrame === 'month') {
        timeframe = date30DaysAgo;
      } else if (this.graphTimeFrame === 'day') {
        timeframe = date1DaysAgo;
      }
      this.apiService.getDataPoints(streamsString, timeframe, limit).subscribe(
        (response: any) => {
          return resolve(response.Items);
        },
        response => {
          return resolve([]);
        });
    });
  }

  viewNotifications(event){
    event.stopPropagation();
    this.viewNotificationCallback.emit(this.device);

  }

  editDevice(event) {
    event.stopPropagation();
    this.editDeviceCallback.emit(this.device);
  }

  close() {
    this.modalRef.dismiss();
  }

  postStreamData(event, measurement, editButton) {
    event.stopPropagation();
    let modalValue = 0;
    const lastData = this.device.lastData;
    if (lastData && lastData[measurement.intersection]) {
      modalValue = lastData[measurement.intersection];
    }
    measurement.modalValue = modalValue;

    this.postDataCallback.emit({
      editButton,
      measurement,
      device: this.device,
    });
  }

  getMessageFromThreashold(measurement, lastData) {
    let message = '';
    if (lastData && lastData[measurement.intersection] && measurement.thresholds) {
      const value = lastData[measurement.intersection];
      measurement.thresholds.forEach((threshold) => {
        if (value >= threshold.range.min  && value <= threshold.range.max ) {
          message = threshold.message;
        }
      });
    }
    return message;
  }

  getColorFromThreashold(measurement, lastData) {
    let tileColor = measurement.tileColor;
    if (lastData && lastData[measurement.intersection] && measurement.thresholds) {
      const value = lastData[measurement.intersection];
      measurement.thresholds.forEach((threshold) => {
        if (value >= threshold.range.min  && value <= threshold.range.max ) {
          tileColor = threshold.tileColor;
        }
      });
    }
    return tileColor;
  }
}
