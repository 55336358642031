import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '../pages/login/login.component';
import { HomeComponent } from '../pages/home/home.component';
import { AdminComponent } from '../pages/admin/admin.component';
import { CustomersComponent } from '../pages/admin_pages/customers/customers.component';
import { DisplayTemplatesComponent } from '../pages/admin_pages/display-templates/display-templates.component';
import { DisplayTemplateGroupsComponent } from '../pages/admin_pages/display-template-groups/display-template-groups.component';
import { NotificationTemplatesComponent } from '../pages/admin_pages/notification-templates/notification-templates.component';
import { NotificationTemplateGroupsComponent } from '../pages/admin_pages/notification-template-groups/notification-template-groups.component';
import { UsersComponent } from '../pages/admin_pages/users/users.component';
import { DevicesComponent } from '../pages/admin_pages/devices/devices.component';
import { DeviceEditComponent } from '../pages/admin_pages/device-edit/device-edit.component';
import { UserEditComponent } from '../pages/admin_pages/user-edit/user-edit.component';
import { DataClassComponent } from '../pages/admin_pages/data-class/data-class.component'

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'admin',
    component: AdminComponent,
  },
  {
    path: 'customers',
    component: CustomersComponent,
  },
  {
    path: 'display-templates',
    component: DisplayTemplatesComponent,
  },
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'user/:username',
    component: UserEditComponent,
  },
  {
    path: 'devices',
    component: DevicesComponent,
  },
  {
    path: 'notificationTemplates',
    component: NotificationTemplatesComponent,
  },
  {
    path: 'notificationTemplateGroups',
    component: NotificationTemplateGroupsComponent,
  },
  {
    path: 'device/:deviceId',
    component: DeviceEditComponent,
  },
  {
    path: 'dataClasses',
    component: DataClassComponent,
  },
  {
    path: 'displayTemplateGroups',
    component: DisplayTemplateGroupsComponent,
  }
  
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
