import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import { Router } from '@angular/router';
import { UserService } from 'src/providers/user/user.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {

  showSpinner = false;
  devices = [];
  filterString = '';
  filteredDevices = [];
  user:any = false;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
  ) {
    this.user = userService.getUser();
    this.showSpinner = true;
    this.getDevices();
  }

  openDevice(device) {
    if(device.found === false) {
      alert(`Can not open ${device.deviceid}.`);
      return;
    }
    this.router.navigate(['/device',device.deviceid]);
  }
  
  filterDevices() {
    this.filteredDevices = [];
    for (let i in this.devices) {
      let device = this.devices[i];
      if(this.filterString == '' || device.deviceid.indexOf(this.filterString) > -1) {
        this.filteredDevices.push(device);
      }
    }
  }
  ngOnInit() {
  }

  getPrettyDate(epoch) {
    if (epoch) {
      return new Date(epoch).toLocaleString();
    }
    return '';
  }

  getDevice(deviceid) {
    return new Promise((resolve, reject) => {
      this.apiService.getDevice(deviceid).subscribe(
        (response: any) => {
         if(response.Count > 0) {
          return resolve(response.Items[0]);
         }
         return resolve(false);
        },
        response => {
          alert(`Get Devices failed: ${deviceid}`);
          return resolve(false);
        });
    })
  }

  async getDevices() {
    if (this.user.devices && this.user.devices.length > 0) {
      this.devices = [];
      for(let i in this.user.devices) {
        const deviceid = this.user.devices[i];
        const dbDevice = await this.getDevice(deviceid);
        if(dbDevice) {
          this.devices.push(dbDevice);
        } else {
          this.devices.push({found: false, deviceid:`${deviceid} - NOT FOUND`, streams:[]})
        }
      }

      this.showSpinner = false;
      this.filterDevices();

    } else if(this.user.roles.includes('api:GET:Poolsense-Device:*')) {
      this.apiService.getDevices().subscribe(
        (response: any) => {
          this.showSpinner = false;
          this.devices = response.Items;
          this.filterDevices();
        },
        response => {
          this.showSpinner = false;
          alert('Get Devices failed');
        });
    } else {
      this.showSpinner = false;
      this.devices = [];
    }
  }

}
