import { Component, OnInit, ViewChild , TemplateRef} from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-display-template-groups',
  templateUrl: './display-template-groups.component.html',
  styleUrls: ['./display-template-groups.component.css']
})

export class DisplayTemplateGroupsComponent implements OnInit {

  @ViewChild('content', {static: true})
  private content: TemplateRef<any>;

  fa = fa;
  showSpinner;
  step;
  modalRef;

  imagePath;
  imgURL;
  imgType;
  message = '';

 displayTemplateGroups = [];
  selectedDisplayTemplateGroup;

  modalHeading = 'Add Display Template Group';
  disableTemplateGroupId = false;

  backMap = {
    photo: 'name',
    templates: 'photo',
  };
  nextMap = {
    name: 'photo',
    photo: 'templates',
  };
  saveMap = {
    templates: true
  };

  displayTemplates: [];

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    ) {
      this.getDisplayTemplateGroups();
      this.getDisplayTemplates();
  }

  getDisplayTemplates() {
    this.showSpinner = true;
    this.apiService.getDisplayTemplates().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.displayTemplates = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get Customers failed');
      });
  }

  getDisplayTemplateGroups() {
    this.showSpinner = true;
    this.apiService.getDisplayTemplateGroup().subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.Items)
          this.displayTemplateGroups = response.Items;
      },
      response => {
        this.showSpinner = false;
        if (response.error){
          alert('Get template groups failed: ' + response.error.message);
        } else
        alert('Get template groups failed: '+ JSON.stringify(response, null, 2));
      });
  }
  ngOnInit() {
  }

  openModal() {
    this.imgURL = undefined;
    this.step = 'name';
    this.modalRef = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'});
  }

  previewImage(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    this.imgType = mimeType.replace('image/', '');
    reader.onload = (event) => {
      this.imgURL = reader.result;
    };
  }

  addTemplateGroup() {
    this.modalHeading = 'Add Display Template Group';
    this.disableTemplateGroupId = false;
    this.selectedDisplayTemplateGroup = {
      templateGroupId : '',
      templateGroupDisplayName : '',
      templateGroupDescription : '',
      displayTemplates : {},
    };
    this.openModal();
  }

  uploadImage() {
    const imageData = this.imgURL.slice(this.imgURL.indexOf(',') + 1 );
    return new Promise((resolve, reject) => {
      this.apiService.uploadImage(
        {
          type: this.imgType,
          path: `displayTemplates/${this.selectedDisplayTemplateGroup.templateGroupId}/logo`,
          name: 'logo',
          image: imageData,
        }).subscribe(
        (response: any) => {
          if (response.url) {
            return resolve(response.url);
          } else {
            alert('image upload failed');
            return reject();
          }
        },
        response => {
          alert('image upload failed');
          return reject();
        });
    });

  }
  async uploadDisplayTemplateGroup() {
    this.showSpinner = true;
    let imageName;
    if (this.imgURL) {
      try {
        imageName = await this.uploadImage();
      } catch (err) {
        alert(`image uplaod failed: ${err}`);
        return;
      }
    }

    const postBody: any = {
      templateGroupId : this.selectedDisplayTemplateGroup.templateGroupId,
      templateGroupDisplayName: this.selectedDisplayTemplateGroup.templateGroupDisplayName,
      templateGroupDescription: this.selectedDisplayTemplateGroup.templateGroupDescription,
      displayTemplates : this.selectedDisplayTemplateGroup.displayTemplates,
      photo: this.selectedDisplayTemplateGroup.photo,
    };

    if (imageName) {
      postBody.photo = imageName;
    }

    this.apiService.updateTempalteGroup(postBody).subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.successful > 0) {
          this.imgURL = undefined;
          this.modalRef.close();
          this.getDisplayTemplateGroups();
        } else {
          alert('Template Group Save failed');
        }
      },
      response => {
        this.showSpinner = false;
        if (response.error){
          alert('Get template groups failed: ' + response.error.message);
        } else
          alert('Template Group Save failed ' + JSON.stringify(response))
      });
  }

  openTemplateGroup(displayTemplateGroup) {
    this.disableTemplateGroupId = true;
    this.modalHeading = 'Update Display Template Group';
    this.selectedDisplayTemplateGroup = JSON.parse(JSON.stringify(displayTemplateGroup));
    this.openModal();
  }

  save() {

    this.uploadDisplayTemplateGroup();
  }

  measurementChange(event) {
    console.log('measurementChange');
    // console.log(this.selectedDisplayTemplate.templateMeasurement);
  }

  getDisplayTemplateCount(templates) {
    if (templates) {
      let counter = 0;
      for (const i in templates) {
        if (templates[i] === true) {
          counter++;
        }
      }
      return counter;
    }
    return 0;
  }

}
