
<app-spinner [show]="showSpinner" ></app-spinner>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div *ngIf="step === 'name'">
        <div class="form-group">
          <div class="form-group">
            <label>Group Identifier</label>
            <input name="templateGroupId" class="form-control" placeholder="Group ID" type="text" [disabled]="disableTemplateGroupId" [(ngModel)]="selectedDisplayTemplateGroup.templateGroupId">
          </div>
          <div class="form-group">
            <label>Display Name</label>
            <input name="templateGroupDisplayName" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedDisplayTemplateGroup.templateGroupDisplayName">
          </div>
          <div class="form-group">
            <label>Description</label>
            <input name="description" class="form-control" placeholder="Description" type="text"  [(ngModel)]="selectedDisplayTemplateGroup.templateGroupDescription">
          </div>
        </div>
      </div>
      <div *ngIf="step === 'photo'">
        <label>Photo</label>
        <span  *ngIf="message">{{message}}</span>
        <input #file type="file" accept='image/*' (change)="previewImage(file.files)" />
        <img [src]="imgURL" width="100%" *ngIf="imgURL">
        <img [src]="selectedDisplayTemplateGroup.photo" width="100%" *ngIf="!imgURL && selectedDisplayTemplateGroup.photo">
      </div>
      <div *ngIf="step === 'templates'">
        <div class="form-group">
          <div class="form-group">
            <label>Display Templates</label>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let displayTemplate of displayTemplates">
                <div class="custom-control custom-checkbox">
                  <input name="{{displayTemplate.templateName}}" type="checkbox" class="custom-control-input" id="{{displayTemplate.templateName}}" [(ngModel)]="selectedDisplayTemplateGroup.displayTemplates[displayTemplate.templateName]" >
                  <label class="custom-control-label" for="{{displayTemplate.templateName}}">{{displayTemplate.templateName}}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-spinner [show]="showSpinner" ></app-spinner>
    <button type="button" class="btn btn-outline-dark" *ngIf="backMap[step] && !showSpinner" (click)="step = backMap[step]">Back</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="nextMap[step] && !showSpinner" (click)="step = nextMap[step]">Next</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="saveMap[step] && !showSpinner" (click)="save()">Save</button>
  </div>
</ng-template>

<div (click)="addTemplateGroup()" style="padding:10px">
  <fa-icon  [icon]="fa.faPlus"></fa-icon>
  Add Template Group
</div>

<table class="table table-striped table-hover" hover="true">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">ID</th>
    <th scope="col">Display Name</th>
    <th scope="col">Description</th>
    <th scope="col">Photo</th>
    <th scope="col">Display Templates</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let displayTemplateGroup of displayTemplateGroups; index as i" (click)="openTemplateGroup(displayTemplateGroup)">
    <th scope="row">{{ i + 1 }}</th>
    <td>{{ displayTemplateGroup.templateGroupId }}</td>
    <td>{{ displayTemplateGroup.templateGroupDisplayName }}</td>
    <td>{{ displayTemplateGroup.templateGroupDescription }}</td>
    <td><img *ngIf="displayTemplateGroup.photo" [src]="displayTemplateGroup.photo" class="mr-2 image" ></td>
    <td>{{ getDisplayTemplateCount(displayTemplateGroup.displayTemplates) }}</td>
  </tr>
  </tbody>
</table>