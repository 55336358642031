<div>
  <div class="d-flex flex-wrap">
    <div class="flex-fill logo-container"> 
        <img *ngIf="config.logo" class="logo" [src]="config.logo" alt="logo"> 
    </div>
    <div class="p-2 flex-fill title-container" >
        <h1>{{config.displayTitle}}</h1>
        <h2>{{config.displayDescription}}</h2>
    </div>
    <div class="flex-fill user-menu">
      <div class="float-right" *ngIf="user">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" ngbDropdown>
            <a class="nav-link pointer" ngbDropdownToggle id="navbarDropdown1" role="button">
                <span style="padding-right:10px">{{user && user.email}}</span>
                <fa-icon class="user-logo" [icon]="fa.faUserCircle" ></fa-icon>
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
              <a *ngIf="hasRole('fe:header:admin')" ngbDropdownItem href="#" (click)="openPage($event,'admin')" i18n>Admin</a>
              <a ngbDropdownItem href="#" (click)="openPage($event,'home')" i18n >Home</a>
              <a ngbDropdownItem href="#" (click)="openPage($event,'logout')" i18n>Logout</a>
            </div>
            </li>
        </ul>
    </div>
    </div>
  </div>
</div>