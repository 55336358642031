
<table class="table table-striped table-hover" hover="true">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">email</th>
    <th scope="col">name</th>
    <th scope="col">surname</th>
    <th scope="col">domain</th>
    <th scope="col">createdAt</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users; index as i" (click)="openUser(user)">
    <th scope="row">{{ i + 1 }}</th>
    <td>{{ user.email }}</td>
    <td>{{ user.name }}</td>
    <td>{{ user.surname }}</td>
    <td>{{ user.domain }}</td>
    <td>{{ getPrettyDate( user.createdAt) }}</td>
  </tr>
  </tbody>
</table>