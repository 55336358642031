import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'src/providers/user/user.service';

@Component({
  selector: 'app-device-edit',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.css']
})
export class DeviceEditComponent implements OnInit {

  // @ViewChild('calibrate', {static: true})
  private calibrate: TemplateRef<any>;
  modalType = 'basicCalibrate';
  step = 'basicCalibrate';
  device:any = false;
  refCalibrationValue;

  selectedCalibrationFunction;


  calibrationArguments =  new FormGroup({}); 
  modalRef;
  sub;
  deviceId;
  calibrateStream;
  mqttStream;
  streams:any = [];
  sleepCounters = [];
  selectedSleepCount:any;
  sleepCount = '';
  states = [];
  selectedState:any;
  updating = true;
  showSpinner = true;
  HumanReadableSate = '';
  sigfoxDeviceUrl = '';
  selectedMqttTopic = '';
  user:any = {};
  tokenData:any = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
    this.user = this.userService.getUser();
   }

  async ngOnInit() {
    this.device = false;
    for(let i = 1 ; i < 255 ; i ++){
      const minutes = i*15;
      const hour = Math.floor((minutes / 60));
      const min = `00${minutes % 60}`.slice(-2);
      this.sleepCounters.push({
        label: `${hour}h${min}`,
        value: i,
      })
    }
    this.states = [{name:"init"},
              {name:"active"}];
    this.sub = this.route.params.subscribe(async (params) => {
      this.deviceId = params['deviceId'];
      this.loadDevice();

    }); 
  }

  getTokenData(device) {
    if (device.tokenData) {
      const { type, activationTime, contractTerm, tokenEnd, tokenState } = device.tokenData;
      this.tokenData = {
        type,
        activationTime: new Date(activationTime).toISOString().split('T').join(' ').substring(0, 19),
        contractTerm,
        contractEnd: new Date((contractTerm * 1000 * 60 * 60 * 24) + activationTime).toISOString().split('T').join(' ').substring(0, 19),
        daysTillContractEnd: Math.floor(((contractTerm * 1000 * 60 * 60 * 24 + activationTime) - new Date().getTime()) / (1000 * 60 * 60 * 24 )),
        tokenEnd: new Date(tokenEnd).toISOString().split('T').join(' ').substring(0, 19),
        tokenState,
      };
    }
  }

  async loadDevice() {
    this.updating = true;
    this.showSpinner = true;
    this.device = await this.getDevice(this.deviceId);
    this.getTokenData(this.device);
    this.streams = await this.getStreams(this.device.streams);
    let sleepCount = 0;
    
    if (this.device.sigfoxDownlink){
      this.decodePoolSenseDownlinkHex(this.device.sigfoxDownlink);
    }
    
    if (this.device.downlink){
      if (this.device.downlink.sleepCounter){
        sleepCount = parseInt(`0x${this.device.downlink.sleepCounter}`);
      }
    }
    
    if (sleepCount > 0){
      this.selectedSleepCount = this.sleepCounters.find((x) => x.value === sleepCount);
      this.sleepCount = this.selectedSleepCount.label;
    }

    this.selectedState = this.states.find((state) => state.name === this.device.activationState);


    if (!this.device.flags){
      this.device.flags = [];
    }
    
    if (!this.device.flags.conductivityFlag){
      this.device.flags.conductivityFlag = false;
    }

    if (!this.device.streamFlag){
      this.device.streamFlag = false;
    }

    this.sigfoxDeviceUrl = 'https://backend.sigfox.com/device/' + parseInt('0x' + this.device.deviceid) + '/info';

    this.showSpinner = false;
    this.updating = false;
  }

  prettyStateName(value) {
    
    switch (value) {
        case 'init':
            return 'Deep Sleep';
        case 'active':
            return 'Active';
        case 'req_active':
            return 'Waiting for first Active Message';
        case 'quality':
            return 'Calibration';
        case 'dongleCalibrateState':
            return 'Dongle Calibrateion';
        case 'faulty':
            return 'Faulty';
  
    }
    return 'Unknown';
}


decodePoolSenseDownlinkHex(downlinkHex){

  if (!this.device.flags){
    this.device.flags = {};
  }

  if (!this.device.downlink)
  {
    this.device.downlink = {};
  }
  const flagsBinString = (`00000000${parseInt(downlinkHex.substring(8,10),16).toString(2)}`).slice(-8);

  this.device.flags.reboot = !!parseInt(flagsBinString.substring(7,8),2);
  this.device.flags.conductivityFlag = !!parseInt(flagsBinString.substring(6,7),2);
  this.device.flags.sneDongleFlag = !!parseInt(flagsBinString.substring(5,6),2);
  this.device.flags.debugFlag = !!parseInt(flagsBinString.substring(4,5),2);
  
  // its unclear what is being used, downlink or flags sub object. so updating both. 
  this.device.downlink.reboot = !!parseInt(flagsBinString.substring(7,8),2);
  this.device.downlink.conductivityFlag = !!parseInt(flagsBinString.substring(6,7),2);
  this.device.downlink.sneDongleFlag = !!parseInt(flagsBinString.substring(5,6),2);
  this.device.downlink.debugFlag = !!parseInt(flagsBinString.substring(4,5),2);
  
  this.device.downlink.sleepCounter = parseInt(downlinkHex.substring(4,6),16).toString(16);
  this.device.downlink.state = parseInt(downlinkHex.substring(6,8),16).toString(16);
  
  if (parseInt(this.device.downlink.state,16) === 0) this.device.activationState =  'active';
  else if (parseInt(this.device.downlink.state,16) === 1) this.device.activationState =  'init';
  else if (parseInt(this.device.downlink.state,16) === 2) this.device.activationState =  'quality';
}

  createPoolSenseDownlinkHex(){

    const flags = this.device.flags;
    let hex = ((flags.reboot | 0 ) + (flags.conductivityFlag << 1) + (flags.sneDongleFlag << 2) + (flags.debugFlag << 3)).toString(16).toUpperCase();
    const configFlags = ('00' + hex).slice(-2);
    let downlinkData = `${(`00${this.device.downlink.sleepCounter}`).slice(-2) + (`00${this.device.downlink.state}`).slice(-2) + configFlags}00`;
    return `00FF${downlinkData}FF00`.toUpperCase();

  }

  async copyV1Calibration(){
    this.updating = true;
    this.showSpinner = true;

    console.log("Copy V1 (poolsense) Calibration");

    this.device.updated_at = new Date().getTime();
    this.apiService.syncCalibrateData(this.device.deviceid).subscribe(
      async (response: any) => {
        this.loadDevice();
        this.updating = false;
        this.showSpinner = false;
        alert('Successfully updated.');
      },
      response => {
        this.showSpinner = false;
        alert('Sync failed:  ' + response.error.message);
        this.updating = false;
      });

     


  }

  async resetStreams(){

    if (confirm(`Are you sure you want to refresh the streams? It may cause calibration data loss.`)){
      this.updating = true;
      this.showSpinner = true;

      console.log("Refreshing streams to new templae");

      this.apiService.refreshDeviceStreams(this.device.deviceid).subscribe(
        async (response: any) => {
          this.loadDevice();
          this.updating = false;
          this.showSpinner = false;
          alert('Successfully refreshed.');

        },
        response => {
          this.showSpinner = false;
          this.loadDevice();
          alert('Stream Refresh failed:  ' + response.error.message);
          this.updating = false;
        });
    }
  }

  getLastDatapoint(stream){
    this.updating = true;
    this.showSpinner = true;
    const date1DaysAgo = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);

    this.apiService.getDataPoints(stream.streamId, date1DaysAgo, 1).subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.updating = false;
        const streamData = {};
        if (response.Items.length > 0){
          response.Items.forEach((item) => {
            if (!streamData[item.streamId]) {
              streamData[item.streamId] = [];
            }
            streamData[item.streamId].push(item);
          });

          
          alert('Last Reading: ' + JSON.stringify(streamData[stream.streamId][0] ));
        } else alert('No Data found')
      },
      response => {
        this.showSpinner = false;
        this.updating = false;
        alert(JSON.stringify(response));
      });
  }

  async startManualCalibration(modal,stream){
    this.modalType = 'basicCalibrate';
    this.step = 'startCalibrate';
    this.calibrateStream = stream;
    this.selectedCalibrationFunction = this.calibrateStream.calibrationFunctions[0];
    this.modalRef = this.modalService.open(modal);
  }

  async getCalibrateArgs(){
    this.modalType = 'basicCalibrate';
    this.calibrationArguments = new FormGroup({});
    for (let arg of this.selectedCalibrationFunction.arguments) {
      this.calibrationArguments.addControl(arg.name,new FormControl("",Validators.required))
   }

    this.step = 'getCalibrateArgs';
    
  }  
  
  async updateBasicCalibration(){
    this.updating = true;
    this.showSpinner = true;

    let StreamCalibrateInfo = {
      "streamId": this.calibrateStream.streamId,
      "calibrationFunction": this.selectedCalibrationFunction.name,
      "arguments": this.calibrationArguments.value
    }

    //adjust for time zone before sending. 
    for (let arg of this.selectedCalibrationFunction.arguments) {
      if (arg.type === "datetime-local")
        StreamCalibrateInfo.arguments[arg.name] =  new Date(StreamCalibrateInfo.arguments[arg.name]).getTime();
   }


    //console.log("Calibration values: " + JSON.stringify(StreamCalibrateInfo));

    this.apiService.streamCalibrate(StreamCalibrateInfo).subscribe(
      async (response: any) => {
        this.modalRef.dismiss(true);
        this.loadDevice();
        this.updating = false;
        this.showSpinner = false;
        if (response.message){
          alert('Successfully calibrated. ' + response.message );
        } else alert('Successfully calibrated.');
        this.showSpinner = false;
      },
      response => {
        this.modalRef.dismiss(true);
        this.showSpinner = false;
        if (response.error){
          if (response.error.message){
            alert('Calibration Failed:  ' + response.error.message);
          } else
          {
            alert('Calibration Failed:  ' + JSON.stringify(response));
          }
        }
        else {
          alert('Calibration Failed:  ' + JSON.stringify(response));
        }
        
        this.updating = false;
        
      });

      


    

    
  }

  async updateEC(){
    this.updating = true;
    this.showSpinner = true;

    this.device.flags.conductivityFlag = !(this.device.flags.conductivityFlag);
    console.log("EC Flag:" + this.device.flags.conductivityFlag);


    if (this.device.sigfoxDownlink){  //only create fixed downlink if already exists
      this.device.sigfoxDownlink = this.createPoolSenseDownlinkHex();
    }

    this.device.updated_at = new Date().getTime();
    this.apiService.updateDevice(this.device).subscribe(
      async (response: any) => {
        this.loadDevice();
        this.showSpinner = false;
        this.updating = false;
        alert('Successfully updated.');
      },
      response => {
        this.showSpinner = false;
        alert('Post failed:  ' + response.error.message);
        this.updating = false;
      });

      


  }

  async updateState() {
    this.updating = true;
    this.showSpinner = true;
    if (this.selectedState){
      this.device.activationState = this.selectedState;
    } 
    console.log('Selected State:' + this.selectedState);
    console.log('Device Activation State:' + this.device.activationState);


    if (this.device.activationState === 'init') {
      this.device.downlink.sleepCounter = 64;
      this.device.downlink.state = 1;
    } else if (this.device.activationState === 'active' || this.device.activationState === 'req_active') {
      if(!this.device.downlink.sleepCounter || this.device.downlink.sleepCounter >= 8) {
        this.device.downlink.sleepCounter = 8;
      }
      this.device.streamFlag = true;
      this.device.downlink.state = 0;
    } else if (this.device.activationState === 'quality') {
      this.device.downlink.sleepCounter = 64;
      this.device.downlink.state = 2;
    } else if (this.device.activationState === 'dongleCalibrateState') {
      this.device.downlink.sleepCounter = 1;
      this.device.downlink.state = 0;
      this.device.flags.sneDongleFlag = true;
    }

    if (this.device.sigfoxDownlink){  //only create fixed downlink if already exists
      this.device.sigfoxDownlink = this.createPoolSenseDownlinkHex();
    }

    this.device.updated_at = new Date().getTime();
    this.apiService.updateDevice(this.device).subscribe(
      async (response: any) => {
        this.loadDevice();
        this.updating = false;
        alert('Successfully updated.');
      },
      response => {
        alert('Post failed:  ' + response.error.message);
        this.updating = false;
      });
      this.showSpinner = false;
  }

  async updateSleepCounter() {
    this.updating = true;
    this.showSpinner = true;
    this.device.downlink.sleepCounter = this.selectedSleepCount.value.toString(16).toUpperCase(); //convert to hex
    //this.sleepCount = this.selectedSleepCount.value;
    //let downlink = '00FF' + ('00' + this.selectedSleepCount.value.toString(16).toUpperCase()).slice(-2) + this.device.sigfoxDownlink.substring(6);
    this.device.sigfoxDownlink = this.createPoolSenseDownlinkHex();
    this.device.updated_at = new Date().getTime();
    this.apiService.updateDevice(this.device).subscribe(
      async (response: any) => {
        this.loadDevice();
        this.updating = false;
        alert('Successfully updated.');
      },
      response => {
        //alert('Post failed ' + JSON.stringify(response));
        alert('Post failed:  ' + response.error.message);
        this.updating = false;
      });
      this.showSpinner = false;
  }



  updateStream(stream) {
    this.showSpinner = true;

    for(let i in stream.datapoints) {
      if(isNaN(stream.datapoints[i])){
        if (!Array.isArray(stream.datapoints[i])) {
          alert(`${i}: ${stream.datapoints[i]}\n is not valid.`);
          return;
        }
      }
    }

    for(let i in stream.mqttTopics) {
      if(stream.mqttTopics[i] == ''){
        alert('Topic can not be empty');
        return;
      }
    }

    this.apiService.postStream(stream).subscribe(
      async (response: any) => {
        if (response.successful) {
          alert('Successfully updated.');
          this.showSpinner = false;
        } else {
          alert(response.message);
          this.showSpinner = false;
        }
      },
      response => {
        this.showSpinner = false;
        alert('Save stream failed');
      });
      
     
  }

  trackByFn(index, item) {
    return index;  
  }

  removeTopic(stream, index) {
    stream.mqttTopics.splice(index, 1);
    this.updateStream(stream);
  }

  selectMqttTopic(modal,stream){
    this.modalType = 'mqttModal';
    this.step = 'selectMqttTopic';
    this.mqttStream = stream;
    
    if (this.getUserMqttTopics()){
      this.user.mqttTopics = this.getUserMqttTopics();
      this.selectedMqttTopic = this.user.mqttTopics[0];
      this.modalRef = this.modalService.open(modal);
    } else alert("No mqtt topics found for this user");
  }

  async addMqttTopic(){

   
    this.showSpinner = true;

    if(!this.mqttStream.mqttTopics){
        this.mqttStream.mqttTopics = [];
      }
    this.mqttStream.mqttTopics.push(this.selectedMqttTopic );

    await this.updateStream(this.mqttStream);
    
    //this.showSpinner = false;
    this.modalRef.dismiss(true);
    

  }

  getUserMqttTopics(){
    if (this.user.mqttTopics && this.user.mqttTopics.length > 0) {
        return this.user.mqttTopics
      } else return false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getPrettyDate(epoch) {
    return new Date(epoch).toLocaleString();
  }

  openStream(stream) {
    alert('openStream');
  }

  getDevice(deviceId) {
    return new Promise((resolve, reject) => {
      this.apiService.getDevice(deviceId).subscribe(
        (response: any) => {
          if (response) {
            if (response.Count === 1) {
              resolve(response.Items[0]);
            } else {
              alert('Device not found.');
              reject('Device not found.');
            }
          } else {
            alert('Device not found.');
            reject('Device not found.');
          }
        },
        (response: any) => {
          alert('Device not found.');
          reject('Device not found.');
        });
    });
  }

  getStreams(streams) {
    return new Promise((resolve, reject) => {
      let streamsString = '';
      for (let i in streams) {
        streamsString = streamsString + ',' + streams[i];
      }
      streamsString = streamsString.substring(1);

      this.apiService.getStreams(streamsString).subscribe(
        (response: any) => {
          if (response) {
            resolve(response.Items);
          } else {
            alert('Device not found.');
            reject('Device not found.');
          }
        },
        (response: any) => {
          this.showSpinner = false;
          alert('Streams error.');
          reject('Streams error.');
        });
    });
  }
}
