import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../providers/api/api.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../providers/user/user.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  @ViewChild('content', {static: true})
  private content: TemplateRef<any>;


  closeResult = '';
  fa = fa;
  showSpinner = true;
  customers = [];
  step = 'name';

  modalHeading = 'Add Customer';
  disableCustomerName = false;

  modalRef;

  imagePath;
  imgURL;
  imgType;
  message = '';

  selectedCustomer = {
    customerName : '',
    subdomain : '',
    adminEmail : '',
    adminOnlyRegistration: false,
    userCanRegister: true,
    displayTitle : '',
    displayDescription : '',
    displayTemplates: {},
    displayTemplateGroups: {},
    notificationTemplateGroups: [],
    group: false,
    templateCategory: false,
    logo:'',
  };

  backMap = {
    display: 'name',
    logo: 'display',
    templateGroups: 'logo',
    templates: 'templateGroups',
    notifications: 'templates'
  };
  nextMap = {
    name: 'display',
    display: 'logo',
    logo: 'templateGroups',
    templateGroups: 'templates',
    templates: 'notifications',
  };
  saveMap = {
    notifications: true
  };

  displayTemplates: [];
  notificationTemplateGroups: [];
  displayTemplateGroups: [];

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
    this.showSpinner = true;
    this.getCustomers();
    this.getDisplayTemplates();
    this.getNotificationTemplateGroups();
    this.getDisplayTemplateGroups();
  }

  getDisplayTemplateGroups() {
    this.showSpinner = true;
    this.apiService.getDisplayTemplateGroup().subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.Items)
          this.displayTemplateGroups = response.Items;
      },
      response => {
        this.showSpinner = false;
        if (response.error){
          alert('Get template groups failed: ' + response.error.message);
        } else
        alert('Get template groups failed: '+ JSON.stringify(response, null, 2));
      });
  }

  getCustomers() {
    this.apiService.getCustomers().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.customers = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get Customers failed');
      });
  }

  getDisplayTemplates() {
    this.showSpinner = true;
    this.apiService.getDisplayTemplates().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.displayTemplates = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get Customers failed');
      });
  }

  getNotificationTemplateGroups() {
    this.showSpinner = true;
    this.apiService.getNotificationTemplateGroups().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.notificationTemplateGroups = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get notification template groups failed');
      });
  }

  openModal() {
    this.step = 'name';
    this.modalRef = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'});
  }

  previewImage(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    this.imgType = mimeType.replace('image/', '');
    reader.onload = (event) => {
      this.imgURL = reader.result;
    };
  }

  ngOnInit() {
  }

  uploadImage() {
    const imageData = this.imgURL.slice(this.imgURL.indexOf(',') + 1 );
    return new Promise((resolve, reject) => {
      this.apiService.uploadImage(
        {
          type: this.imgType,
          path: `customers/${this.selectedCustomer.subdomain}/logo`,
          name: 'logo',
          image: imageData,
        }).subscribe(
        (response: any) => {
          if (response.url) {
            return resolve(response.url);
          } else {
            alert('image upload failed');
            return reject();
          }
        },
        response => {
          alert('image upload failed');
          return reject();
        });
    });

  }
  async uploadCustomer() {

    this.selectedCustomer.notificationTemplateGroups = [];
    this.notificationTemplateGroups.forEach((template:any) => {
      if(template.checked){
        this.selectedCustomer.notificationTemplateGroups.push(template.notificationTemplateGroupId);
      }
    })

    this.showSpinner = true;
    // let email = this.userService.getUser().email;
    let imageName;

    if (this.imgURL) {
      try {
        imageName = await this.uploadImage();
      } catch (err) {
        alert(`image uplaod failed: ${err}`);
        return;
      }
    }

    for (const i in this.selectedCustomer.displayTemplates) {
      if (!this.selectedCustomer.displayTemplates[i]) {
        delete this.selectedCustomer.displayTemplates[i];
      }
    }

    for (const i in this.selectedCustomer.displayTemplateGroups) {
      if (!this.selectedCustomer.displayTemplateGroups[i]) {
        delete this.selectedCustomer.displayTemplateGroups[i];
      }
    }

    for (const i in this.selectedCustomer.notificationTemplateGroups) {
      if (!this.selectedCustomer.notificationTemplateGroups[i]) {
        delete this.selectedCustomer.notificationTemplateGroups[i];
      }
    }

    const postBody: any = {
      // created_by: email,
      customerName: this.selectedCustomer.customerName,
      subdomain: this.selectedCustomer.subdomain,
      adminEmail: this.selectedCustomer.adminEmail,
      adminOnlyRegistration: this.selectedCustomer.adminOnlyRegistration,
      userCanRegister: this.selectedCustomer.userCanRegister,
      displayTitle: this.selectedCustomer.displayTitle,
      displayDescription: this.selectedCustomer.displayDescription,
      displayTemplates: this.selectedCustomer.displayTemplates,
      displayTemplateGroups: this.selectedCustomer.displayTemplateGroups,
      notificationTemplateGroups: this.selectedCustomer.notificationTemplateGroups,
      group: this.selectedCustomer.group,
      templateCategory: this.selectedCustomer.templateCategory,
      logo: this.selectedCustomer.logo,
    };

    if (imageName) {
      postBody.logo = imageName;
    }

    this.apiService.postData('Proautomation-Customer-Portal', postBody).subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.successful > 0) {

          this.imgURL = undefined;
          this.modalRef.close();
          this.getCustomers();
        } else {
          alert('Register failed');
        }
      },
      response => {
        this.showSpinner = false;
        alert('Register failed ' + response);
      });
  }

  save() {
    this.uploadCustomer();
  }

  addCustomer() {

    this.notificationTemplateGroups.forEach((template:any) => template.checked = false);

    this.disableCustomerName = false;
    this.modalHeading = 'Add Customer';
    this.selectedCustomer = {
      customerName : '',
      subdomain : '',
      adminEmail : '',
      adminOnlyRegistration : false,
      userCanRegister: true,
      displayTitle : '',
      displayDescription : '',
      displayTemplates : {},
      displayTemplateGroups : {},
      notificationTemplateGroups : [],
      group: false,
      templateCategory: false,
      logo:'',
    };
    this.openModal();
  }

  getDisplayTemplateCount(templates) {
    if (templates) {
      let counter = 0;
      for (const i in templates) {
        if (templates[i] === true) {
          counter++;
        }
      }
      return counter;
    }
    return 0;
  }

  getNotificationTemplateGroupCount(group) {
    if (group) {
      let counter = 0;
      for (const i in group) {
        if (group[i] === true) {
          counter++;
        }
      }
      return counter;
    }
    return 0;
  }

  openCustomer(customer) {


    if (!customer.notificationTemplateGroups) {
      customer.notificationTemplateGroups = [];
    }

    this.notificationTemplateGroups.forEach((template:any) => {
      const checked = customer.notificationTemplateGroups.find((key) => key === template.notificationTemplateGroupId)
      if (checked) {
        template.checked = true;
      } else {
        template.checked = false;
      }
      return template;
    });

    this.disableCustomerName = true;
    this.modalHeading = 'Update Customer';
    this.selectedCustomer = JSON.parse(JSON.stringify(customer));
    if (!this.selectedCustomer.displayTemplates) {
      this.selectedCustomer.displayTemplates = {};
    }

    if (!this.selectedCustomer.displayTemplateGroups) {
      this.selectedCustomer.displayTemplateGroups = {};
    }
    
    this.openModal();
  }
}
