<div class="login-container">
    <article class="login-card-body">
        <div *ngIf="passwordReset" >
            <button (click)="passwordReset = false" class="float-right btn btn-outline-primary" >Cancel</button>
            <h4 class="card-title mb-4 mt-1">Password Reset</h4>
            <div>
                <div class="form-group">
                    <label>Your new password</label>
                    <input class="form-control" placeholder="******" type="password" [(ngModel)]="password">
                </div> <!-- form-group// --> 
                <div class="form-group">
                    <label>Confirm new password</label>
                    <input class="form-control" placeholder="******" type="password" [(ngModel)]="passwordConfirm">
                </div> <!-- form-group// --> 
                <div class="form-group">
                    <button (click)="resetPassword()" class="btn btn-primary btn-block">Reset Password</button>
                </div> <!-- form-group// -->                                                           
            </div>
        </div>

        <div *ngIf="activateAccount" >
            <h4 class="card-title mb-4 mt-1">{{activateMessage}}</h4>
        </div>

        <div *ngIf="!passwordReset && !showSignUp && !activateAccount" >
            <button (click)="toggleSignUp()" *ngIf="userCanRegister" class="float-right btn btn-outline-primary" >Sign up</button>
            <h4 class="card-title mb-4 mt-1">Sign in</h4>
            <div>
                <div class="form-group">
                    <label>Your email</label>
                    <input name="" class="form-control" placeholder="Email" type="email" [(ngModel)]="email">
                </div> <!-- form-group// -->
                <div class="form-group">
                    <a class="float-right" href="#" tabindex="-1" (click)="forgotPassword()" >Forgot?</a>
                    <label>Your password</label>
                    <input class="form-control" placeholder="******" type="password" [(ngModel)]="password">
                </div> <!-- form-group// --> 
                <div class="form-group">
                    <button (click)="login()" class="btn btn-primary btn-block">Login</button>
                </div> <!-- form-group// -->                                                           
            </div>
        </div>
        <div *ngIf="!passwordReset && showSignUp &&  !activateAccount" >
            <button (click)="toggleSignUp()" class="float-right btn btn-outline-primary" >Sign in</button>
            <h4 class="card-title mb-4 mt-1">Sign up</h4>
            <div>
                <div class="form-group">
                    <label>Your email</label>
                    <input name="" class="form-control" placeholder="Email" type="email" [(ngModel)]="email">
                </div> <!-- form-group// -->
                <div class="form-group">
                    <label>Your name</label>
                    <input name="" class="form-control" placeholder="Name" type="text" [(ngModel)]="name">
                </div> <!-- form-group// -->
                <div class="form-group">
                    <label>Your surname</label>
                    <input name="" class="form-control" placeholder="Surname" type="text" [(ngModel)]="surname">
                </div> <!-- form-group// -->
                <div class="form-group">
                    <label>Your password</label>
                    <input class="form-control" placeholder="******" type="password" [(ngModel)]="password">
                </div> <!-- form-group// --> 
                <div class="form-group">
                    <label>Confirm password</label>
                    <input class="form-control" placeholder="******" type="password" [(ngModel)]="passwordConfirm">
                </div> <!-- form-group// --> 
                <div class="form-group">
                    <button (click)="signUp()" class="btn btn-primary btn-block">Sign up</button>
                </div> <!-- form-group// -->                                                           
            </div>
        </div>
    </article>
</div>
<app-spinner [show]="showSpinner" ></app-spinner>
<div class="page-version">{{version}}</div>