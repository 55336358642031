<div class="device-container">
  <div *ngIf="device" class="d-flex flex-wrap device" >
    <div class="flex-fill name-tile" >
      <div class="flex-fill name-text">{{device.name}}</div>
      <div class="flex-fill name-serial">{{device.serial}}</div>
      <div class="flex-fill name-lastSeen">{{device.lastSeen}}</div>
      <div class="flex-fill name-notificationView"><fa-icon [icon]="fa.faEye" (click)="viewNotifications($event)"></fa-icon></div> 
      <div class="flex-fill name-edit"><fa-icon *ngIf="canEditDevice" [icon]="fa.faEdit" (click)="editDevice($event)"></fa-icon></div> 
    </div>

    <div class="device-image-block">
      <img *ngIf="device.imagePath" class="device-image" [src]="device.imagePath" width="100%"   />
      <img *ngIf="!device.imagePath" class="device-image" [src]="device.template.photo" width="100%"   />
    </div>

    <div *ngFor="let measurement of device.template.templateMeasurement" class="p-3 flex-fill tile blue pointer tile-container" [ngStyle]='getTileColor(measurement,device.lastData)'  (click)="showChart($event,device,measurement)">
      <div >
        <div *ngFor="let editButton of measurement.editButtons" class="{{editButton.position}}"><fa-icon [icon]="fa[editButton.icon]" (click)="postStreamData($event,measurement,editButton)"></fa-icon></div>
        <b>{{measurement.name}}</b>
        
        <div *ngIf="device.loading"><fa-icon class="icon-spinner" [icon]=fa.faSpinner></fa-icon></div>
        <div *ngIf="!device.loading && measurement.tileType === 'gauge'" id="{{measurement.intersection}}" style="margin-left: -15px;"></div>
        <div *ngIf="!device.loading && measurement.tileType !== 'gauge'">
          <fa-icon [icon]=fa[measurement.icon]></fa-icon>
          <div *ngIf="!device.loading">{{getValue(measurement,device.lastData)}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="device.showChart && graphMessage" [ngStyle]='getMessageColor()'class="threshold-message" >
      {{graphMessage}}
    </div>
    <div *ngIf="device.showChart" class="chart-container" >
      <button (click)="setTimeFrame($event, 'day')" class="btn btn-primary btn-block" [class.shadow]= "graphTimeFrame === 'day'">Day</button>
      <button (click)="setTimeFrame($event, 'week')" class="btn btn-primary btn-block" [class.shadow]= "graphTimeFrame === 'week'">Week</button>
      <button (click)="setTimeFrame($event, 'month')" class="btn btn-primary btn-block" [class.shadow]= "graphTimeFrame === 'month'">Month</button>
      <div *ngIf="loadingGraph">
        loading...
      </div>
      <div *ngIf="device.showChart" class="chart-canvas-container" >
        <canvas *ngIf="!loadingGraph" baseChart
          #myChart="base-chart"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [type]="barChartType"
          class="chart">
        </canvas>
      </div>
    </div>
  </div>
</div>


