<app-spinner [show]="showSpinner" ></app-spinner>

<!-- Modals start here -->
<ng-template #classModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Class: {{selectedClass.displayName}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form >
            <div *ngIf="step === 'editClass'">
              <label>Class JSON</label>
              <textarea name="measurement" rows="20" class="form-control" placeholder="{}" type="text" [(ngModel)]="classJson"></textarea>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button *ngIf="step === 'editClass'" type="button" class="btn btn-outline-dark" (click)="saveClass();" >Save</button>
      </div>
    <!-- </div> -->
  </ng-template>

  <!-- Page Starts here -->
<h2>Data Classes</h2>

<table class="table table-striped table-hover" hover="true">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Display Name</th>    
      <th scope="col">Description</th>
      <th scope="col">Device Template</th>
      <th scope="col">Update Date</th>
      <th scope="col">Class ID</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let class of classes; index as i" (click)="openClass(classModal,class)">
      <th scope="row">{{ i + 1 }}</th>
      <td>{{ class.displayName }}</td>
      <td>{{ class.description }}</td>
      <td>{{ class.deviceTemplateId }}</td>
      <td>{{ getPrettyDate( class.created_at) }}</td>
      <td>{{ class.dataClassId }}</td>
    </tr>
    </tbody>
  </table>
