import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  showSpinner = true;
  username;
  sub;
  deviceRegistrations = [];
  userInfo;
  pushTokens = [];
  roles = [];
  notificationTemplateGroups = [];
  updating = true;
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    ) {

      this.sub = this.route.params.subscribe(async (params) => {
        this.username = params['username'];
        this.loadUser();
        this.getRegistrations();
      }); 

    
   }

  ngOnInit(): void {
  }

  async loadUser(){
    this.updating = true;
    this.showSpinner = true;
    this.apiService.getUser(this.username).subscribe(
      async (response: any) => {
        
        if (response) {
          this.userInfo = response.Items[0];
          if (this.userInfo){
            if (this.userInfo.pushTokens){
              this.pushTokens = this.userInfo.pushTokens;
            }
            if (this.userInfo.roles){
              this.roles = this.userInfo.roles;
            }
            this.updating = false;
            this.showSpinner = false;
          }
        }
      },
      response => {
        this.showSpinner = false;
      });

  }

  getPrettyDate(epoch) {
    return new Date(epoch).toLocaleString();
  }

  async removePushToken(tokenIndex){
    this.showSpinner = true;
    this.userInfo.pushTokens.splice(tokenIndex,1);

    this.apiService.updateUser(this.userInfo).subscribe(
      async (response: any) => {
        
        if (response) {
          alert('Token Removed');
          this.showSpinner = false;
        }
      },
      response => {
        this.showSpinner = false;
        if (response.error){
          if (response.error.message){
            alert("Failed to remove Token: " + response.error.message);
          } else alert("Failed to remove Token: " + JSON.stringify(response.error));
        } else alert("Failed to remove Token: " + JSON.stringify(response));

      });

   
  }

   async refreshPushToken(tokenIndex){
    this.showSpinner = true;
    
    const domain = window.location.hostname;
    //save the token
    let token = this.userInfo.pushTokens[tokenIndex];
    if (!token.domain){
      console.log(`Set token domain ${domain}`);
      token.domain = domain;
    }
    
    //first remove the token    
    this.userInfo.pushTokens.splice(tokenIndex,1);
    this.apiService.updateUser(this.userInfo).subscribe(
      async (response: any) => {
        
        if (response) {
          console.log('Token Removed');

          //next re-add the token
          this.apiService.updatePush({email: this.userInfo.email, token: token}).subscribe(
            (response: any) => {
              console.log('Added');
              alert('Token Refreshed');
              this.loadUser();
              this.showSpinner = false;
            },
            response => {
              console.log('Added2');
              alert('Token Refreshed');
              this.loadUser();
              this.showSpinner = false;
              
            });
          
        }
      },
      response => {
        this.showSpinner = false;
        if (response.error){
          if (response.error.message){
            alert("Failed to remove Token: " + response.error.message);
          } else alert("Failed to remove Token: " + JSON.stringify(response.error));
        } else alert("Failed to remove Token: " + JSON.stringify(response));

      });

   
  }


  async getRegistrations() {
    this.showSpinner = true;
    this.apiService.getRegistrations(this.username).subscribe(
      async (response: any) => {
        
        if (response) {
          let devicesString = '';
          this.deviceRegistrations = response.Items;

          this.apiService.getNotificationTemplateGroups().subscribe(
            (response: any) => {
              
              this.notificationTemplateGroups = response.Items;

              for (let r = 0; r < this.deviceRegistrations.length; r += 1 ){
                if (this.deviceRegistrations[r].notificationTemplateGroup){
                  const notificationGroup = this.notificationTemplateGroups.find(o => o.notificationTemplateGroupId === this.deviceRegistrations[r].notificationTemplateGroup);
                  this.deviceRegistrations[r].notificationTemplateGroupName = notificationGroup.name;
                }
              }
              this.showSpinner = false;
            },
            response => {
              this.showSpinner = false;
              alert('Get notification groups failed');
            });
                  
        }
      },
      response => {
        this.showSpinner = false;
      });

     
  }

}
