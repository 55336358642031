import { Component, OnInit, ViewChild , TemplateRef} from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-notification-template-groups',
  templateUrl: './notification-template-groups.component.html',
  styleUrls: ['./notification-template-groups.component.css']
})
export class NotificationTemplateGroupsComponent implements OnInit {

  @ViewChild('content', {static: true})
  private content: TemplateRef<any>;

  fa = fa;
  showSpinner;
  step;
  modalRef;

  message = '';

  notificationTemplateGroups = [];
  notificationTemplates = [];

  selectedNotificationTemplateGroup;
  
  modalHeading = 'Add Notification Template Group';

  backMap = {
    templates: 'name',
  };
  nextMap = {
    name: 'templates',
  };
  saveMap = {
    templates: true
  };

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    ) {
      this.getNotificationTemplateGroups();
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getNotificationTemplateGroups() {
    this.showSpinner = true;
    this.apiService.getNotificationTemplateGroups().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.notificationTemplateGroups = response.Items;
        this.getNotificationTemplates();
      },
      response => {
        this.showSpinner = false;
        alert('Get Customers failed');
      });
  }

  getNotificationTemplates() {
    this.showSpinner = true;
    this.apiService.getNotificationTemplates().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.notificationTemplates = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get Customers failed');
      });
  }


  ngOnInit() {
  }

  openModal() {
    this.step = 'name';
    this.modalRef = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'});
  }

  addTemplate() {
    this.modalHeading = 'Add Notification Template Group';
    this.selectedNotificationTemplateGroup = {
      notificationTemplateGroupId: this.uuidv4(),
      name : '',
      description : '',
      notificationsTemplates : [],
    };
    this.notificationTemplates.forEach((template) => template.checked = false);
    this.openModal();
  }

  async uploadNotificationTemplate() {
    
    this.showSpinner = true;

    for(let i in this.selectedNotificationTemplateGroup){
      if (this.selectedNotificationTemplateGroup[i] === '') {
        delete this.selectedNotificationTemplateGroup[i];
      }
    }

    this.apiService.postData('Proautomation-Notification-Template-Group', this.selectedNotificationTemplateGroup).subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.successful >= 1) {
          this.modalRef.close();
          this.getNotificationTemplateGroups();
        } else {
          alert('Post failed');
        }
      },
      response => {
        this.showSpinner = false;
        alert('Post failed ' + response);
      });
  }

  openTemplate(notificationTemplate) {
    this.modalHeading = 'Update Notification Template Group';
    
    this.notificationTemplates.forEach((template) => {
      const checked = notificationTemplate.notificationsTemplates.find((key) => key === template.notificationTemplateId)
      if (checked) {
        template.checked = true;
      } else {
        template.checked = false;
      }
      return template;
    });

    this.selectedNotificationTemplateGroup = JSON.parse(JSON.stringify(notificationTemplate));
    this.openModal();
  }

  getNotificationTemplateGroupCount(group) {
    if (group) {
      let counter = 0;
      for (const i in group) {
        if (group[i] === true) {
          counter++;
        }
      }
      return counter;
    }
    return 0;
  }

  save() {
    
    this.selectedNotificationTemplateGroup.notificationsTemplates = [];
    this.notificationTemplates.forEach((template) => {
      if(template.checked){
        this.selectedNotificationTemplateGroup.notificationsTemplates.push(template.notificationTemplateId);
      }
    })
    this.uploadNotificationTemplate();
  }
}
