<app-spinner [show]="showSpinner" ></app-spinner>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div *ngIf="step === 'name'">
        <div class="form-group">
          <div class="form-group">
            <label>Customer Name</label>
            <input name="customerName" class="form-control" placeholder="Name" type="text" [disabled]="disableCustomerName" [(ngModel)]="selectedCustomer.customerName">
          </div>
          <div class="form-group">
            <label>Sub-domain URL</label>
            <input name="subdomain" class="form-control" placeholder="Sub-domain" type="text"  [(ngModel)]="selectedCustomer.subdomain">
          </div>
          <div class="form-group">
            <label>Admin Email</label>
            <input name="adminEmail" class="form-control" placeholder="Email" type="email" [(ngModel)]="selectedCustomer.adminEmail">
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input name="group" type="checkbox" class="custom-control-input" id="adminOnlyRegistration" [(ngModel)]="selectedCustomer.adminOnlyRegistration" >
              <label class="custom-control-label" for="adminOnlyRegistration">Admin only registrations</label>
              <br>
              <span style="font-size:10px;font-style: italic;">(All users on this domain will see the Admin accounts registrations)</span>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input name="group" type="checkbox" class="custom-control-input" id="userCanRegister" [(ngModel)]="selectedCustomer.userCanRegister" >
              <label class="custom-control-label" for="userCanRegister">Users can register</label>
              <br>
              <span style="font-size:10px;font-style: italic;">(User will be able to create an account on the login page)</span>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input name="group" type="checkbox" class="custom-control-input" id="templateCategory" [(ngModel)]="selectedCustomer.templateCategory" >
              <label class="custom-control-label" for="templateCategory">Display Template Category</label>
              <br>
              <span style="font-size:10px;font-style: italic;">(Categories will as you to select a category first to filter the display templates)</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="step === 'display'">
        <div class="form-group">
          <div class="form-group">
            <label>Display Title</label>
            <input name="displayTitle" class="form-control" placeholder="Title" type="text" [(ngModel)]="selectedCustomer.displayTitle">
          </div>
          <div class="form-group">
            <label>Display Description</label>
            <input name="displayDescription" class="form-control" placeholder="Description" type="text" [(ngModel)]="selectedCustomer.displayDescription">
          </div>
          <div class="form-group">
            <div class="form-group">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" >
                  <div class="custom-control custom-checkbox">
                    <input name="group" type="checkbox" class="custom-control-input" id="group" [(ngModel)]="selectedCustomer.group" >
                    <label class="custom-control-label" for="group">groups</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="step === 'logo'">
        <label>Logo</label>
        <span  *ngIf="message">{{message}}</span>
        <input #file type="file" accept='image/*' (change)="previewImage(file.files)" />
        <img [src]="imgURL" width="100%" *ngIf="imgURL">
        <img [src]="selectedCustomer.logo" width="100%" *ngIf="!imgURL && selectedCustomer.logo">
      </div>
      <div *ngIf="step === 'templateGroups'">
        <div class="form-group">
          <div class="form-group">
            <label>Display Template Groups</label>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let displayTemplateGroup of displayTemplateGroups">
                <div class="custom-control custom-checkbox">
                  <input name="{{displayTemplateGroup.templateGroupId}}" type="checkbox" class="custom-control-input" id="{{displayTemplateGroup.templateGroupId}}" [(ngModel)]="selectedCustomer.displayTemplateGroups[displayTemplateGroup.templateGroupId]" >
                  <label class="custom-control-label" for="{{displayTemplateGroup.templateGroupId}}">{{displayTemplateGroup.templateGroupDisplayName}}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="step === 'templates'">
        <div class="form-group">
          <div class="form-group">
            <label>Display Templates</label>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let displayTemplate of displayTemplates">
                <div class="custom-control custom-checkbox">
                  <input name="{{displayTemplate.templateName}}" type="checkbox" class="custom-control-input" id="{{displayTemplate.templateName}}" [(ngModel)]="selectedCustomer.displayTemplates[displayTemplate.templateName]" >
                  <label class="custom-control-label" for="{{displayTemplate.templateName}}">{{displayTemplate.templateName}}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="step === 'notifications'">
        <div class="form-group">
          <div class="form-group">
            <label>Notification Groups</label>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let notificationTemplateGroup of notificationTemplateGroups">
                <div class="custom-control custom-checkbox">
                  <input name="{{notificationTemplateGroup.name}}" type="checkbox" class="custom-control-input" id="{{notificationTemplateGroup.name}}" [(ngModel)]="notificationTemplateGroup.checked" >
                  <label class="custom-control-label" for="{{notificationTemplateGroup.name}}">{{notificationTemplateGroup.name}}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" *ngIf="backMap[step]" (click)="step = backMap[step]">Back</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="nextMap[step]" (click)="step = nextMap[step]">Next</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="saveMap[step]" (click)="save()">Save</button>
  </div>
</ng-template>

<div (click)="addCustomer()" style="padding:10px">
  <fa-icon  [icon]="fa.faPlus"></fa-icon>
  Add customer
</div>

<table class="table table-striped table-hover" hover="true">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">Logo</th>
    <th scope="col">Name</th>
    <th scope="col">Domain</th>
    <th scope="col">Email</th>
    <th scope="col">Title</th>
    <th scope="col">Description</th>
    <th scope="col">AdminRegistrations</th>
    <th scope="col">SignUp</th>
    <th scope="col">Group</th>
    <th scope="col">TemplateCategory</th>
    <th scope="col">Display Templates</th>
    <th scope="col">Notification Groups</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let customer of customers; index as i" (click)="openCustomer(customer)">
    <th scope="row">{{ i + 1 }}</th>
    <td><img *ngIf="customer.logo" [src]="customer.logo" class="mr-2 logo"></td>
    <td>{{ customer.customerName }}</td>
    <td>{{ customer.subdomain }}</td>
    <td>{{ customer.adminEmail }}</td>
    <td>{{ customer.displayTitle }}</td>
    <td>{{ customer.displayDescription }}</td>
    <td>{{ customer.adminOnlyRegistration }}</td>
    <td>{{ customer.userCanRegister }}</td>
    <td>{{ customer.group }}</td>
    <td>{{ customer.templateCategory }}</td>
    <td>{{ getDisplayTemplateCount(customer.displayTemplates) }}</td>
    <td>{{ customer.notificationTemplateGroups && customer.notificationTemplateGroups.length }}</td>
  </tr>
  </tbody>
</table>