<app-spinner [show]="showSpinner" ></app-spinner>
<ng-template #content let-modal>
  <div *ngIf="modalType === 'addComment'" class="modal-container">
    <div  class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="modal-comment" id="modal-basic-comment">{{comments}}</p>
      <form>
        <div>
          <label>Add Comment</label>
          <input name="postValue" class="form-control" placeholder="Comment" [(ngModel)]="modalValue">                                                       
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="submitComment()">Submit</button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </div>
  <div *ngIf="modalType === 'postData'" class="modal-container" >
    <div  class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div>
          <label>Value</label>
          <input name="postValue" class="form-control" placeholder="0" type="number" [(ngModel)]="modalValue">
        </div>
        <div *ngIf="showTimestamp">
          <label>Timestamp</label>
          <input type="datetime-local" name="postTimestamp" class="form-control" [(ngModel)]="modalTimestamp">
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="submit()">Submit</button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </div>
  <div *ngIf="modalType === 'device'" class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="step == 'displayTemplateGroups'">
        <label *ngIf="displayTemplateGroups.length > 0" >Please select a device type from the options below:</label><br>
        <div *ngFor="let displayTemplateGroup of displayTemplateGroups" class="display-tempate-container" (click)="selectDisplayTempalateGroup(displayTemplateGroup.templateGroupId)">
          <label  class="modal-label" [for]="displayTemplateGroup.templateGroupId"> {{displayTemplateGroup.templateGroupDisplayName}} </label>
          <img class="modal-image" [src]="displayTemplateGroup.photo" >
        </div>
      </ng-container>
        <ng-container *ngIf="step == 'deviceTemplate'">
          <label *ngIf="displayTemplates.length > 0" class="display-templates-instruciton">Please select the device:</label><br>
          <div *ngFor="let template of displayTemplatesFiltered" class="display-tempate-container" (click)="selectDisplayTemplate(template.templateName)">
            <label class="modal-label" [for]="template.templateName"> {{template.templateName}} </label>
            <img class="modal-image" [src]="template.photo" >
          </div>
        </ng-container>

      <form>
           <div *ngIf="step === 'deviceId'">
            <label>Device ID</label>
            <input name="deviceId" class="form-control" placeholder="Device ID" type="text" [(ngModel)]="selectedDevice.serial" [disabled]='disableDeviceId'>
          </div>
          <div *ngIf="step === 'deviceName'">
            <label>Device Name</label>
            <input name="deviceName" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedDevice.name">
            <label>Device Description</label>
            <input name="deviceDescription" class="form-control" placeholder="Description" type="text" [(ngModel)]="selectedDevice.description">
            <label *ngIf="hasGroups" >Group</label>
            <input *ngIf="hasGroups" name="deviceGroup" class="form-control" placeholder="Group" type="text" [(ngModel)]="selectedDevice.group">
            <br>
            <label *ngIf="notificationTemplateGroups.length > 0" >Notification Group</label><br>
            <select *ngIf="notificationTemplateGroups.length > 0" name="notificationTemplateGroup" [(ngModel)]="selectedDevice.notificationTemplateGroup">
              <option *ngFor="let group of notificationTemplateGroups" [value]="group.notificationTemplateGroupId" >{{group.name}}</option>
            </select>
            
            <div *ngIf="selectedDisplayTemplate.userInputFields">
              <form [formGroup]="userInputFieldsForm">
                  <div *ngFor="let field of selectedDisplayTemplate.userInputFields">
                    <label for='field.name'>{{field.displayName}}: </label>
                    <input *ngIf="!field.options" class="form-control" type="{{field.type}}" [formControlName]='field.name' id='field.name' value='ture'> 
                    <select *ngIf="field.options" class="form-control" [formControlName]='field.name'> 
                      <option *ngFor="let option of field.options | keyvalue" [value]="option.key">{{option.value}}</option>
                    </select>
                  </div>
              </form>
            </div>

            <div *ngIf="user.mqttTopics">
              <label>MQTT topic to add:  </label>
              <select [(ngModel)]="selectedMqttTopic" name="mqttTopicSelect">
                <option [ngValue]="" ></option>
                <option *ngFor="let topic of user.mqttTopics" [ngValue]="topic" >{{topic}}</option>
              </select> 
            </div>

            

          </div>
          <div *ngIf="step === 'deviceImage'">
            <span>Please select a custom image for your device.  You may skip this step. </span>
            <input #file type="file" accept='image/*' (change)="previewImage(file.files)" />
            <img [src]="imgURL" width="100%" *ngIf="imgURL">
            <img [src]="selectedDevice.imagePath" width="100%" *ngIf="!imgURL && selectedDevice.imagePath">
          </div>
          <div *ngIf="step === 'finalize'">
            <table>
              <tr>
                <td>ID:</td>
                <td>{{selectedDevice.serial}}</td>
              </tr>
              <tr>
                <td>Name:</td>
                <td>{{selectedDevice.name}}</td>
              </tr>
              <tr>
                <td>Description:</td>
                <td>{{selectedDevice.description}}</td>
              </tr>
              <tr *ngIf="selectedDevice.displayTemplate">
                <td>Display template:</td>
                <td>{{selectedDevice.displayTemplate}}</td>
              </tr>
              <tr *ngIf="selectedDevice.notificationTemplateGroup">
                <td>Notification Group:</td>
                <td>{{getNotificationGroupName(selectedDevice.notificationTemplateGroup)}}</td>
              </tr>
              <tr *ngIf="hasGroups">
                <td>Group:</td>
                <td>{{selectedDevice.group}}</td>
              </tr>
            </table>
            <div class="image-container" *ngIf="imgURL">
              <img class="image" [src]="imgURL" width="100%"   />
            </div>
            <div class="image-container" *ngIf="! imgURL && selectedDevice.imagePath">
              <img class="image" [src]="selectedDevice.imagePath" width="100%"   />
            </div>
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button *ngIf="showDelete" type="button" class="btn btn-outline-dark" (click)="deleteDevice(selectedDevice);">Delete</button>
      <button *ngIf="showDeviceConfig" type="button" class="btn btn-outline-dark" (click)="openDevice(selectedDevice);">Device Config</button>
      <button type="button" class="btn btn-outline-dark btn-right" *ngIf="backMap[step]" (click)="modalBack();">Back</button>
      <button type="button" class="btn btn-outline-dark" *ngIf="nextMap[step]" (click)="modalNext();">Next</button>
      <button type="button" class="btn btn-outline-dark" *ngIf="saveMap[step]" (click)="addRegistration();">Save</button>
    </div>
  </div>
  <div *ngIf="modalType === 'notifications'" class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table>
        <!-- notifications heading -->
        <tr>
          <td>Date</td>
          <td>Title</td>
          <td>Message</td>
          <td>Email</td>
          <td>Push</td>

        </tr>
        <!-- list of notificaitons  -->
        <tr *ngFor="let notification of selectedNotificationData" style="text-align: center;" >
          <td>{{getPrettyDate(notification.timestamp)}}</td>
          <td>{{notification.title}}</td>
          <td>{{notification.message}}</td>
          <td>{{notification.transport.email}}</td>
          <td>{{notification.transport.pushNotification}}</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>
<div class="body-background-container">
  <video playsinline autoplay muted loop id="bgvid" class="welcome-video">
    <source src="assets/background-video.mp4" type="video/mp4">
 </video>
</div>
<div >
    <div class="welcome-container">
      
      <h3 ><span i18n>Welcome back</span> {{user.name}}</h3>
      <div class="welcome-container-custom" [innerHTML]="config.welcomeMessage">
                
      </div>
    </div>
    <div *ngIf="canAddDevices" (click)="addDevice()" style="padding:10px" class="add-device-container">
        <fa-icon  [icon]="fa.faPlus"></fa-icon>
        <span i18n> Add device</span>
    </div>
    <div *ngIf="hasGroups" class="groups-container">
        <div *ngFor="let group of groupsArray" class="d-flex flex-wrap"  style="text-align: center;" (click)="toggleGroup($event, group)">
            <div class="group">
                    <fa-icon *ngIf="group.value.expanded" [icon]="fa.faCaretDown" style="padding-right:5px"></fa-icon>
                    <fa-icon *ngIf="!group.value.expanded" [icon]="fa.faCaretRight" style="padding-right:5px"></fa-icon>
                    {{group.key}}
                <b style="float:right">{{group.value.devices.length}}</b>
            </div>
            <div *ngIf="group.value.expanded">
                <div *ngFor="let deviceRegistration of group.value.devices">
                    <device (addCommentCallback)="addComment($event)" [canEditDevice]="canAddDevices" (editDeviceCallback)="editDevice(deviceRegistration)" (viewNotificationCallback)="viewNotification(deviceRegistration)"  (postDataCallback)="postData($event,deviceRegistration)" [device]="deviceRegistration" [loading]="deviceRegistration.loading"></device>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="noGroups" class="groups-container">
        <div *ngFor="let deviceRegistration of deviceRegistrationsArray">
          <div class="d-flex flex-wrap"  style="text-align: center;">
            <device (addCommentCallback)="addComment($event)" [canEditDevice]="canAddDevices" (editDeviceCallback)="editDevice(deviceRegistration.value)" (viewNotificationCallback)="viewNotification(deviceRegistration)" (postDataCallback)="postData($event,deviceRegistration.value)" [device]="deviceRegistration.value" [loading]="deviceRegistration.value.loading"></device>
          </div>
        </div>
    </div>
</div>

<div >
  <div class="body-footer">
          <div *ngIf="config.footerPhone" class="body-footer-phone">
            <fa-icon  [icon]=fa.faPhone></fa-icon>
            {{config.footerPhone}}
          </div>
          <div *ngIf="config.footerEmail" class="body-footer-email">
            <fa-icon [icon]=fa.faEnvelope></fa-icon>
            {{config.footerEmail}}
          </div>
           <div class="body-footer-custom" [innerHTML]="config.footerMessage">
    </div>
  </div>

<div class="footer">
  <div *ngIf="canAddDevices" (click)="addDevice()" class="p-2 w-50 flex-fill" >
    <fa-icon  [icon]="fa.faPlus"></fa-icon>
    <span i18n> Add device</span>
  </div>
</div> 
