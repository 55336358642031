import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../providers/config/config.service';
import { UserService } from '../../providers/user/user.service';
import { Router } from '@angular/router';
import { ApiService } from '../../providers/api/api.service';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  fa = fa;
  config: any = {
    displayTitle: '',
    displayDescription: '',
  };
  headerConfigService : ConfigService;
  user;
  constructor(private configService: ConfigService, 
              private userService: UserService,  
              private router: Router, 
              apiService: ApiService ) {
    this.config = configService.getConfig();
    this.user = userService.getUser();
    this.headerConfigService = configService;
    userService.change.subscribe(user => {
      this.user = user;
    });

    this.configService.getConfigChangeEmitter().subscribe(config => {
        this.config = config;
    });


    this.configService.getConfigChangeEmitter().subscribe(config => {
        this.config = config;
    });
      
      

    this.config = configService.getConfig();
    const domain = window.location.hostname;


      this.tryAutoLoginLocal();

      //if (!this.user && router.url !== '/') {
      if (!this.user) {
        console.log("No user found, navigate to login")
        router.navigate(['']);
      }
      const pathSplit = window.location.href.split('#');
      if (!this.user && pathSplit.length > 1 && pathSplit[1] !== '/' ) {
        console.log('URL split:' + pathSplit[1]);
        router.navigate(['']);
      }

  }

   ngOnInit() {
     
   }

   async tryAutoLoginLocal(){
    const authInfoString =  localStorage.getItem('authInfo');
    if (authInfoString){
      const authInfo = JSON.parse(authInfoString);
      if (authInfo.expire > (Date.now()/1000) )
      {
        this.userService.setToken(authInfo.authToken, '');
      } else {
        localStorage.removeItem('authInfo'); // remove if expired
      }
    }
  }
    

  hasRole(role) {
    return this.user && this.user.roles && this.user.roles.indexOf(role) !== -1;
  }

  openPage(event, item) {
    event.preventDefault();
    if (item === 'logout') {
      this.userService.logout();
      this.user = false;
      //localStorage.removeItem('authInfo');
      localStorage.clear();
      this.router.navigate(['']);
      this.headerConfigService.clearConfig();
    } else if (item === 'admin') {
      this.router.navigate(['/admin']);
    } else if (item === 'home') {
      this.router.navigate(['/home']);
    }
  }

}
