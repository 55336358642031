import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../../providers/api/api.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-data-class',
  templateUrl: './data-class.component.html',
  styleUrls: ['./data-class.component.css']
})
export class DataClassComponent implements OnInit {

  modalType = 'class';
  showSpinner = false;
  classes = [];
  step = 'editClass';
  classJson : string;
  selectedClass : any;
  modalRef;

  constructor(private apiService: ApiService,
    private router: Router,
    private modalService: NgbModal,
    ) {
    this.showSpinner = true;
    this.getClasses();
   }

  ngOnInit() {

  }
  getPrettyDate(epoch) {
    return new Date(epoch).toLocaleString();
  }

  getClasses() {
    this.apiService.getClasses().subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.classes = response.Items;
      },
      response => {
        this.showSpinner = false;
        alert('Get classes failed');
      });
  }

  openClass(modal,inputClass) {
   this.modalType = 'class';
   this.step = 'editClass';
   this.showSpinner = true;
    
   //get the latest verstion of the class 
   this.apiService.getClass(inputClass.dataClassId).subscribe(
    (response: any) => {
      this.showSpinner = false;
      this.selectedClass = response.Items[0];
      this.classJson = JSON.stringify(this.selectedClass, null, 2);
      this.modalRef = this.modalService.open(modal);
    },
    response => {
      this.showSpinner = false;
      alert('Get classes failed');
    });
  }

  saveClass(){
    this.showSpinner = true;

    try {
      this.selectedClass = JSON.parse(this.classJson);
    } catch (err) {
      alert('Data Class not valid');
      this.showSpinner = false;  
      return;
    }

    this.apiService.updateClass(this.selectedClass).subscribe(
      (response: any) => {
        this.showSpinner = false;
        this.modalRef.dismiss(true);
        this.getClasses();
        alert('Data Class Saved');
        
      },
      response => {
        this.showSpinner = false;
        this.modalRef.dismiss(true);
        alert('Get classes failed');
      });
  }
 
}
