
<app-spinner [show]="showSpinner" ></app-spinner>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{modalHeading}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div *ngIf="step === 'name'">
        <div class="form-group">
          <div class="form-group">
            <label>Name</label>
            <input name="name" class="form-control" placeholder="Name" type="text" [(ngModel)]="selectedNotificationTemplateGroup.name">
          </div>
          <div class="form-group">
            <label>Description</label>
            <input name="description" class="form-control" placeholder="Description" type="text" [(ngModel)]="selectedNotificationTemplateGroup.description">
          </div>
        </div>
      </div>
      <div *ngIf="step === 'templates'">
        <div class="form-group">
          <div class="form-group">
              <label>Templates</label>
              <ul class="list-group list-group-flush" >
                <li *ngFor="let notificationTemplate of notificationTemplates; index as i"  class="list-group-item" >
                  <div class="custom-control custom-checkbox">
                    <input name="cb_{{notificationTemplate.name}}" type="checkbox" class="custom-control-input" id="{{notificationTemplate.name}}" [(ngModel)]="notificationTemplate.checked" >
                    <label class="custom-control-label" for="{{notificationTemplate.name}}">{{notificationTemplate.name}}</label>
                  </div>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" *ngIf="backMap[step]" (click)="step = backMap[step]">Back</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="nextMap[step]" (click)="step = nextMap[step]">Next</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="saveMap[step]" (click)="save()">Save</button>
  </div>
</ng-template>

<div (click)="addTemplate()" style="padding:10px">
  <fa-icon  [icon]="fa.faPlus"></fa-icon>
  Add Notification
</div>

<table class="table table-striped table-hover" hover="true">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">Name</th>
    <th scope="col">Description</th>
    <th scope="col">Notifications</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let notificationTemplateGroup of notificationTemplateGroups; index as i" (click)="openTemplate(notificationTemplateGroup)">
    <th scope="row">{{ i + 1 }}</th>
    <td>{{ notificationTemplateGroup.name }}</td>
    <td>{{ notificationTemplateGroup.description }}</td>
    <td>{{ notificationTemplateGroup.notificationsTemplates.length }}</td>
  </tr>
  </tbody>
</table>