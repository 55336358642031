import { Component, OnInit, ViewChild, TemplateRef, ViewChildren, ElementRef, QueryList } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../providers/user/user.service';
import { Router } from '@angular/router';
import { ApiService } from '../../providers/api/api.service';
import { ConfigService } from '../../providers/config/config.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DeviceComponent } from '../../components/device/device.component';
import { SwPush } from '@angular/service-worker';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChildren(DeviceComponent) deviceComponents!: QueryList<DeviceComponent>;

  @ViewChild('content', {static: true})
  private content: TemplateRef<any>;
  @ViewChildren('mycharts') allMyCanvas: any;  // Observe #mycharts elements
  charts: any;    // Array to store all my charts

  showChart = false;
  modalType = 'device';
  modalHeading = 'Register device';
  showTimestamp = false;
  modalUnit = '';
  disableDeviceId = false;
  showDelete = false;
  showDeviceConfig = false;
  modalRef;
  modalValue;
  modalTimestamp;
  step = 'deviceId';
  imagePath;
  imgURL;
  imgType;
  message = '';
  currentMeasurement;
  currentEditButton;
  currentDevice;

  selectedDevice = {
    id: false,
    serial: '',
    name: '',
    description: '',
    poolSize: 0,
    group: '',
    displayTemplate: '',
    notificationTemplateGroup: '',
    imagePath: '',
    userInputFields: {},
    mqttTopic : ''
  };

  selectedDisplayTemplateGroup = {
    displayTemplates : []
  };

  selectedDisplayTemplateGroupId = '';

  backMap = {
    deviceTemplate: 'displayTemplateGroups',
    deviceId: 'deviceTemplate',
    deviceName: 'deviceId',
    deviceImage: 'deviceName',
    finalize: 'deviceImage',
  };
  nextMap = {
    displayTemplateGroups: 'deviceTemplate',
    deviceTemplate: 'deviceId',
    deviceId: 'deviceName',
    deviceName: 'deviceImage',
    deviceImage: 'finalize',
  };
  saveMap = {
    finalize: true
  };

  fa = fa;
  user: any = {
    email: '',
  };
  showSpinner = false;
  deviceRegistrations = {};
  deviceRegistrationsArray = [];
  groupsArray = [];
  devices = {};
  graphStreams = {};
  tileStreams = {};
  groups = {};
  hasGroups = false;
  noGroups = false;

  adminOnlyRegistration = false;
  canAddDevices = true;
  registrationUser = '';
  config;
  defaultTemplate;
  showAddDeviceModal = false;
  displayTemplates = [];
  displayTemplatesFiltered = [];
  displayTemplateGroups = [];
  notificationTemplateGroups = [];
  selectedDisplayTemplate = { userInputFields :[] };
  userInputFieldsForm =  new FormGroup({}); 
  selectedMqttTopic = "";
  notificationData = {};
  selectedNotificationData = [];

  comments = '';
  readonly VAPID_PUBLIC_KEY = "BBMdmao6x8g8qYtzSIkAnfG9trhS-DsSLsgMXjdg83ekMLbxyPBN_1dHAoSv7OLENvh5QUe9_3pPMX2Y-lTcQBE";

  constructor(
    private userService: UserService,
    private router: Router,
    private apiService: ApiService,
    private configService: ConfigService,
    private modalService: NgbModal,
    private elementRef: ElementRef,
    private swPush: SwPush,
    ) {

      // this.configService.getConfigChangeEmitter().subscribe(config => {
      //   this.config = config;
      //   console.log("config emitter" + JSON.stringify(this.config));
      //   this.handleConfig();
      // });

      this.user = this.userService.getUser();
      this.registrationUser = this.user.email;

      this.config = configService.getConfig();

      if (this.config.customer != "") {
        this.hasGroups = this.config.group;
        this.noGroups = !this.config.group;
      }

      if (this.config.adminOnlyRegistration) {
        this.adminOnlyRegistration = true;
        if (this.config.adminEmail !== this.user.email) {
          this.canAddDevices = false;
          this.registrationUser = this.config.adminEmail;
        }
      }
      this.defaultTemplate = configService.getDefaultTemplate();

      this.charts = [
        {
          id: '1',   // Just an identifier
          chart: []            // The chart itself is going to be saved here
        },
        {
          id: '2',
          chart: []
        },
        {
          id: '3',
          chart: []
        }
      ];
      // Reload every 3 minutes.
      setInterval(() => { this.reloadAllDevices(); }, 180000);

      swPush.messages.subscribe(msg => {
        console.log(`Push msg: ${JSON.stringify(msg)}`);
      });

      swPush.notificationClicks.subscribe(msg => {
        console.log(`Notification click msg: ${JSON.stringify(msg)}`);
      });
  }

  async handleConfig() {
    if (this.config.adminOnlyRegistration) {
      this.adminOnlyRegistration = true;
      if (this.config.adminEmail !== this.user.email) {
        this.canAddDevices = false;
        this.registrationUser = this.config.adminEmail;
      }
    }
    if (this.config.customer != "") {
      this.hasGroups = this.config.group;
      this.noGroups = !this.config.group;

    }

    

    try{
      await this.getDisplayTemplates();
    } catch (err) {
      console.log('ERROR' + err);
    }

    try{
      await this.getNotificationTemplateGroups();
    } catch (err) {
      console.log('ERROR' + err);
    }

    try{
      await this.getDisplayTemplateGroups();
    } catch (err) {
      console.log('ERROR' + err);
    }
    
    // try{
    //   await this.ngOnInit();
    // } catch (err) {
    //   console.log('ERROR' + err);
    // }


    this.loadGroups(); 

    this.reloadAllDevices();

  }

  async reloadAllDevices() {
    for(let i in this.deviceRegistrationsArray) {
      await this.reloadDeviceDataPoints(this.deviceRegistrationsArray[i].key);
    }
  }

  getNotificationGroupName(id) {
    const group = this.notificationTemplateGroups.find((group) => group.notificationTemplateGroupId === id);
    if(group){
      return group.name;
    }
    return '';
  }
  getNotificationTemplateGroups() {
    return new Promise((resolve) => {
      if(this.config.notificationTemplateGroups){
        let notificationTemplateGroupIndexes = '';
        this.config.notificationTemplateGroups.forEach((group) => {
          notificationTemplateGroupIndexes = `${notificationTemplateGroupIndexes},${group}`;
        })
        notificationTemplateGroupIndexes = notificationTemplateGroupIndexes.substring(1);
        if (notificationTemplateGroupIndexes !== '') {
          this.apiService.getNotificationTemplateGroups(notificationTemplateGroupIndexes).subscribe(
            (response: any) => {
              this.notificationTemplateGroups = response.Items;
              return resolve(true);
            },
            response => {
              return resolve(true);
            });
        } else {
          return resolve(true);
        }
      }
      return resolve(true);
    });
  }

  
  getDisplayTemplateGroups() {
  
    return new Promise((resolve) => {
      let templateGroupIndexes = '';
      if (this.config.displayTemplateGroups){
        for (const i in this.config.displayTemplateGroups) {
          if (this.config.displayTemplateGroups[i]) {
            templateGroupIndexes = `${templateGroupIndexes},${i}`;
          }
        }
        templateGroupIndexes = templateGroupIndexes.substring(1);
        if (templateGroupIndexes !== '') {
          this.apiService.getDisplayTemplateGroup(templateGroupIndexes).subscribe(
            (response: any) => {
              this.displayTemplateGroups = response.Items;
              return resolve(true);
            },
            response => {
              return resolve(true);
            });
        } else {
          return resolve(true);
        }
      } else {
        return resolve(true);
      }
    });
  }

  getDisplayTemplates() {
    return new Promise((resolve) => {
      let templateIndexes = '';
      for (const i in this.config.displayTemplates) {
        if (this.config.displayTemplates[i]) {
          templateIndexes = `${templateIndexes},${i}`;
        }
      }
      templateIndexes = templateIndexes.substring(1);
      if (templateIndexes !== '') {
        this.apiService.getDisplayTemplates(templateIndexes).subscribe(
          (response: any) => {
            this.displayTemplates = response.Items;
            return resolve(true);
          },
          response => {
            return resolve(true);
          });
      } else {
        return resolve(true);
      }
    });
  }

  getDeviceData(streams, limit?) {
    let streamsString = '';
    streams.forEach(stream => {
      streamsString += ',' + stream;
    });
    streamsString = streamsString.replace(',', '');
    return new Promise((resolve) => {
      const date30DaysAgo = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
      const date7DaysAgo = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);

      this.apiService.getDataPoints(streamsString, date7DaysAgo, limit).subscribe(
        (response: any) => {
          const streamData = {};
          response.Items.forEach((item) => {
            if (!streamData[item.streamId]) {
              streamData[item.streamId] = [];
            }
            streamData[item.streamId].push(item);
          });
          return resolve(streamData);
        },
        response => {
          return resolve(true);
        });
    });
  }

  async getDevicesData() {
    this.generateArray(this.deviceRegistrations).forEach((item: any) => {
      const deviceRegistration = item.value;
      this.graphStreams[deviceRegistration.serial] = [];
      this.tileStreams[deviceRegistration.serial] = [];
      deviceRegistration.template.templateMeasurement.forEach(measurement => {
        const device = this.devices[deviceRegistration.serial];
        if (device) {
          if (measurement.graphClasses) {
            const measurementStreams =
              measurement.graphClasses.map((measurementClass) => `${deviceRegistration.serial}_${measurementClass}`);
            const intersection = measurementStreams.filter(classes => device.streams.includes(classes));
            if (intersection.length > 0) {
              measurement.intersection = intersection[0];
            }
            this.graphStreams[deviceRegistration.serial] = this.graphStreams[deviceRegistration.serial].concat(intersection);

          }

          if (measurement.tileClasses) {
            const measurementStreams =
              measurement.tileClasses.map((measurementClass) => `${deviceRegistration.serial}_${measurementClass}`);
            const intersection = measurementStreams.filter(classes => device.streams.includes(classes));
            if (intersection.length > 0) {
              measurement.intersection = intersection[0];
            }
            this.tileStreams[deviceRegistration.serial] = this.tileStreams[deviceRegistration.serial].concat(intersection);
          }
        }
      });
    });

    for (const i in this.graphStreams) {
      if (this.graphStreams[i].length === 0) {
        continue;
      }
      // removed not to load all stuffs.
      //const streamData = await this.getDeviceData(this.graphStreams[i]);
      //this.deviceRegistrations[i].streamData = streamData;
    }

    for (const i in this.tileStreams) {
      if (this.tileStreams[i].length === 0) {
        continue;
      }
      const streamData: any = await this.getDeviceData(this.tileStreams[i], 1);
      this.deviceRegistrations[i].lastData = {};
      for (const j in streamData) {
        if (j) {
          this.deviceRegistrations[i].lastData[j] = streamData[j][0].value;
        }
      }
    }

  }
  addDevice() {
    if (this.config.templateCategory) { this.step = 'displayTemplateGroups'; }
      else { 
        this.displayTemplatesFiltered = this.displayTemplates;
        this.step = 'deviceTemplate'; 
      }
    this.modalHeading = 'Register device';
    this.disableDeviceId = false;
    this.showDelete = false;
    this.showDeviceConfig = false;
    this.selectedDevice = {
      id:false,
      serial: '',
      name: '',
      description: '',
      poolSize: 0,
      group: '',
      displayTemplate: '',
      imagePath: '',
      notificationTemplateGroup: '',
      userInputFields: {},
      mqttTopic: ''
    };

    if (this.displayTemplates.length > 0 ) {
      this.selectedDevice.displayTemplate = this.displayTemplates[0].templateName;
    }

    this.imagePath = '';
    this.imgURL = '';
    this.imgType = '';
    this.message = '';
    this.modalType = 'device';
    this.modalRef = this.modalService.open(this.content);
  }

  generateArray(obj) {
    const response = Object.keys(obj).map((key) => {
      return { key, value: obj[key]};
    });
    return response;
  }

  addComment(point) {
    this.comments = '';
    this.currentMeasurement = point;
    this.currentDevice = this.deviceRegistrations[point.deviceId];
    this.modalType = 'addComment';

    this.modalValue = '' ;
    if (point.comments && point.comments.length > 0) {
      this.modalValue = point.comments[point.comments.length - 1].comment;

      let commentString = '';
      point.comments.forEach((comment) => {
        const timestamp = new Date(comment.timestamp).toISOString();
        const timeString = timestamp.substr(11,5) + ' ' + timestamp.substr(0,10).replace(/-/g, '/');
        commentString = `${commentString}[${timeString}] ${comment.user}: ${comment.comment}\n`;
      })
      commentString = commentString.slice(0,-1);
      this.comments = commentString;
    }

    const timestampString = new Date(point.timestamp).toISOString().replace('T', ' ').substring(0, 19);
    this.modalHeading = `Add comment ${point.deviceId} ${timestampString}`;
    this.showDelete = false;
    this.modalRef = this.modalService.open(this.content);
  }

  openDevice(device) {
    if(device.found === false) {
      alert(`Can not open ${device.serial}.`);
      return;
    }
    console.log("Navigate direct to Device Settings:" + device.serial)
    this.modalRef.close();
    this.router.navigate(['/device',device.serial]);
  }

  deleteDevice(device) {
    
    if (confirm(`Are you sure you want to delete ${device.name}`)) {
      this.showSpinner = true;
      device.deleted = true;
      let deleteDevice = {
        deleted: true,
        id: device.id,
        serial: device.serial,
        deviceId: device.serial,
        email: device.email,
      }
      this.apiService.postData('Poolsense-User-Device-Registrations', deleteDevice).subscribe(
        (response: any) => {
          this.showSpinner = false;
          if (response.successful === 1) {
            this.apiService.loginRefresh(this.user.email).subscribe(
              (loginResponse: any) => {
                this.userService.setToken(loginResponse.token, this.user.password);
                this.modalRef.close();
                delete this.deviceRegistrations[device.serial];
                this.generateDeviceArray();
                this.loadGroups();
                this.showSpinner = false;
              },
              error => {
                this.showSpinner = false;
                alert('Reload failed. Please log in again.' + JSON.stringify(error));
                
              });
  
          } else {
            this.showSpinner = false;
            alert('Delete failed');
          }
        },
        response => {
          this.showSpinner = false;
          alert('Delete failed ' + response);
        });
    }
    
  }

  hasRole(role) {
    return this.user && this.user.roles && this.user.roles.indexOf(role) !== -1;
  }

  editDevice(device) {
    this.step = 'deviceName';
    this.modalHeading = `Edit ${device.serial}`;
    this.showDelete = true;
    this.showDeviceConfig = this.hasRole('fe:admin:devices');
    this.disableDeviceId = true;
    this.selectedDevice = device;
    this.imagePath = '';
    this.imgURL = '';
    this.imgType = '';
    this.message = '';
    this.modalType = 'device';
     
    this.selectedDisplayTemplate = this.displayTemplates.find(template => template.templateName === this.selectedDevice.displayTemplate );
    
    //restore info from device info, if none use display template defaults. 
    this.userInputFieldsForm = new FormGroup({});
    if (this.selectedDisplayTemplate.userInputFields){
      for (let field of this.selectedDisplayTemplate.userInputFields) {
        if (this.selectedDevice.userInputFields && this.selectedDevice.userInputFields[field.name])
          this.userInputFieldsForm.addControl(field.name,new FormControl(this.selectedDevice.userInputFields[field.name],Validators.required))
        else
          this.userInputFieldsForm.addControl(field.name,new FormControl(field.default,Validators.required))
      }
    }

    if (this.selectedDevice.mqttTopic)
      this.selectedMqttTopic = this.selectedDevice.mqttTopic
    else
      this.selectedMqttTopic = "";


    this.modalRef = this.modalService.open(this.content);
  }

  viewNotification(device){
    //console.log(`View notificaitons for ${JSON.stringify(device, null, 2)}`);
    const date7DaysAgo = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
    let limit = 1000;

    this.modalType = 'notifications';

    this.selectedNotificationData = this.notificationData[device.serial];
    this.modalHeading = `Notifications for ${device.serial}`;

    this.modalRef = this.modalService.open(this.content);

    return new Promise((resolve) => {
    this.apiService.getNotifications(this.user.email, date7DaysAgo, limit).subscribe(
      (response: any) => {
        this.notificationData = {};
        response.Items.forEach((item) => {
          if (!this.notificationData[item.deviceId]) {
            this.notificationData[item.deviceId] = [];
          }
          this.notificationData[item.deviceId].push(item);
        });
        this.selectedNotificationData = this.notificationData[device.serial];
        console.log(`found notificaitons: ${JSON.stringify( this.selectedNotificationData, null, 2)}`);
        return resolve(this.notificationData);
      },
      response => {
        return resolve(true);
      });
  });

   // this.notifications = this.apiService.getNotifications(user, filter, limit?)

  }

  getPrettyDate(epoch) {
    if (epoch) {
      return new Date(epoch).toLocaleString();
    }
    return '';
  }

  async reloadDeviceDataPoints(deviceId, streamId?) {
    let lastSeen = 0;
    // Do not load below on init, only when click on.
    if (this.graphStreams[deviceId].length !== 0) {
      if (streamId) {
        const streamData:any = await this.getDeviceData([streamId]);

        if (!this.deviceRegistrations[deviceId].streamData) {
          this.deviceRegistrations[deviceId].streamData = {};
        }

        Object.keys(streamData).map((key) => {
          this.deviceRegistrations[deviceId].streamData[key] = streamData[key];
        });
      }else {
        // const streamData = await this.getDeviceData(this.graphStreams[deviceId]);
        // this.deviceRegistrations[deviceId].streamData = streamData;
      }
    } else {
      this.deviceRegistrations[deviceId].streamData = {};
    }

    if (!this.deviceRegistrations[deviceId].streamData) {
      this.deviceRegistrations[deviceId].streamData = {};
    }

    if (this.tileStreams[deviceId].length !== 0) {
      const streamData: any = await this.getDeviceData(this.tileStreams[deviceId], 1);
      this.deviceRegistrations[deviceId].lastData = {};
      for (const j in streamData) {
        if (j) {
          this.deviceRegistrations[deviceId].lastData[j] = streamData[j][0].value;
          if (streamData[j][0].timestamp > lastSeen){
            lastSeen = streamData[j][0].timestamp;
          }
        }
      }
    }
    this.deviceRegistrations[deviceId].loading = false;
    this.deviceRegistrations[deviceId].lastSeen = this.getLastSeen(lastSeen);
  }

  submitComment() {
    if (!this.currentMeasurement.comments) {
      this.currentMeasurement.comments = [];
    }

    this.currentMeasurement.comments.push({
      timestamp: new Date().getTime(),
      user: this.user.email,
      comment: this.modalValue,
    });

    const streamId = `${this.currentDevice.serial}_${this.currentMeasurement.measurementClass}`;
    this.apiService.postComment(
      {
        streamId: streamId,
        timestamp: this.currentMeasurement.timestamp,
        comments: this.currentMeasurement.comments,
      }).subscribe(
      async (response: any) => {
        this.modalRef.dismiss(true);
        await this.reloadDeviceDataPoints(this.currentDevice.serial, streamId);
        const device = this.deviceComponents.find((item) => item.device.serial === this.currentDevice.serial );
        if (device) {
          device.reloadGraphData();
        }
      },
      response => {
        alert('Comment failed');
      });
  }

  submit() {
    this.showSpinner = true;
    this.apiService.postStreamData(
      {
        classId: this.currentEditButton.class,
        deviceId: this.currentDevice.serial,
        value: this.modalValue,
        timestamp: new Date(this.modalTimestamp).getTime(),
      }).subscribe(
      async (response: any) => {
        if (response.newStream) {
          this.apiService.loginRefresh(this.user.email).subscribe(
            (loginResponse: any) => {
              this.userService.setToken(loginResponse.token, this.user.password);
              this.modalRef.dismiss(true);
              this.loadDeviceData(this.currentDevice.serial);
              this.loadGroups();
            },
            error => {
              alert('Reload failed. Please log in again.');
            });
        } else {
          this.modalRef.dismiss(true);
        }
        const streamId = `${this.currentDevice.serial}_${this.currentEditButton.class}`;
        await this.reloadDeviceDataPoints(this.currentDevice.serial, streamId);
        const device = this.deviceComponents.find((item) => item.device.serial === this.currentDevice.serial );
        if (device) {
          device.reloadGraphData();
        }
      },
      response => {
        alert('Post failed');
      });
      this.showSpinner = false;
  }

  postData(data,data2) {
    let {
      editButton,
      measurement,
      device,
    } = data

    this.currentEditButton = editButton;
    this.currentMeasurement = measurement;
    this.currentDevice = device;
    this.modalType = 'postData';
    this.modalHeading = editButton.title || 'Set Value';
    this.showDelete = false;
    this.showTimestamp = editButton.timestamp;
    this.modalUnit = measurement.unit;
    this.modalTimestamp = new Date().toISOString().slice(0, 16);
    // this.modalTimestamp = new Date();
    this.modalValue = measurement.modalValue || 0 ;
    this.modalRef = this.modalService.open(this.content);
  }

  getDeviceStreams() {

  }

  async loadDeviceData(devicesString) {
    if(!devicesString || devicesString === ""){
      return;
    }
    const dbDevices: any = await this.getDevices(devicesString);
    dbDevices.forEach((dbDevice) => {

      const deviceId = dbDevice.deviceid;
      let device = this.devices[deviceId];
      const deviceRegistration = this.deviceRegistrations[deviceId];

      if (!device || device.updated_at !== dbDevice.updated_at || deviceRegistration.reload) {
        deviceRegistration.reload = false;
        this.devices[deviceId] = dbDevice;
        device = dbDevice;
        this.graphStreams[deviceId] = [];
        this.tileStreams[deviceId] = [];
        
        if (device.streams){
          deviceRegistration.template.templateMeasurement.forEach(measurement => {
            if (measurement.graphClasses) {
              const measurementStreams = measurement.graphClasses.map((measurementClass) => `${deviceId}_${measurementClass}`);
              const intersection = measurementStreams.filter(classes => device.streams.includes(classes));
              if (intersection.length > 0) {
                measurement.graphIntersection = intersection[0];
              } else {
                measurement.graphIntersection = measurementStreams[0];
              }
              this.graphStreams[deviceId] = this.graphStreams[deviceId].concat(intersection);
            }

            if (measurement.tileClasses) {
              const measurementStreams = measurement.tileClasses.map((measurementClass) => `${deviceId}_${measurementClass}`);
              const intersection = measurementStreams.filter(classes => device.streams.includes(classes));
              if (intersection.length > 0) {
                measurement.intersection = intersection[0];
              }
              this.tileStreams[deviceId] = this.tileStreams[deviceId].concat(intersection);
            }
          });
        }
        this.reloadDeviceDataPoints(deviceId);
      }
    });

    this.generateDeviceArray();

    this.loadGroups();
  }



  loadGroups() {
    if (this.config.group) {
      this.hasGroups = true;
      this.noGroups = false;
      this.groups = {};
      for (const i in this.deviceRegistrations) {
        if (i) {
          const deviceRegistration: any = this.deviceRegistrations[i];
          let group = this.deviceRegistrations[i].name;
          if (deviceRegistration.group) {
            group = deviceRegistration.group;
          }
          if (!this.groups[group]) {
            this.groups[group] = {expanded: false, devices: []};
          }
          this.groups[group].devices.push(deviceRegistration);
        }
      }
      this.groupsArray = this.generateArray(this.groups);
    } else {
      this.hasGroups = false;
      this.noGroups = true;
    }
  }
  getRegistrations() {
    let changedDevices = false;
    let newDevices = false;
    this.apiService.getRegistrations(this.registrationUser).subscribe(
      async (response: any) => {
        
        if (response) {
          let devicesString = '';
          const deviceRegistrations = response.Items;
          if (deviceRegistrations) {
            for (const i in deviceRegistrations) {
              if (i) {
                let deviceRegistration = deviceRegistrations[i];
                const deviceId = deviceRegistration.serial;
                let currentDevice = this.deviceRegistrations[deviceId];

                if (deviceRegistration.deleted) {
                  if (currentDevice) {
                    delete this.deviceRegistrations[deviceId];
                  }
                  return;
                }
                if (!currentDevice || currentDevice.updated_at < deviceRegistration.updated_at) {
                  devicesString = `${devicesString},${deviceId}`;
                  changedDevices = true;
                  if(!currentDevice){
                    newDevices = true;
                    currentDevice = {};
                  }

                  deviceRegistration = Object.assign(currentDevice,deviceRegistration);
                  deviceRegistration.reload = true;
                  this.deviceRegistrations[deviceId] = deviceRegistration;
                  deviceRegistration.template = JSON.parse(JSON.stringify(
                    this.displayTemplates.find((template) =>
                      template.templateName === deviceRegistration.displayTemplate ) || this.defaultTemplate));
                  deviceRegistration.lastSeen = 'No recent message.';
                  deviceRegistration.loading = true;
                }
              }
            }
          }

          if (deviceRegistrations.length > 0 && changedDevices) {
            if (newDevices) {
              this.generateDeviceArray();
            }
            devicesString = devicesString.substring(1);
            this.loadDeviceData(devicesString);
          }
        }
      },
      response => {
        //this.showSpinner = false;
      });

      //this.showSpinner = false;
  }
  generateDeviceArray() {
    let updatedDeviceArray = this.generateArray(this.deviceRegistrations);
    this.deviceRegistrationsArray.forEach(device => device.value.found = false);
    updatedDeviceArray.forEach((device) => {
      let oldDevice = this.deviceRegistrationsArray.find((item) => item.key === device.key);
      if (oldDevice) {
        oldDevice.value.found = true;
        oldDevice.value = Object.assign(oldDevice.value,device.value);
      } else {
        device.value.found = true;
        this.deviceRegistrationsArray.push(device);
      }
    })
    this.deviceRegistrationsArray = this.deviceRegistrationsArray.filter(device => device.value.found);
    this.deviceRegistrationsArray.forEach(device => delete device.value.found);
  }
  
  async ngOnInit() {
    this.showSpinner = true;
    if (!this.user) {
      this.router.navigate(['']);
      return;
    }
    try{
      await this.getDisplayTemplates();
    } catch (err) {
      console.log('ERROR' + err);
    }

    try{
      await this.getNotificationTemplateGroups();
    } catch (err) {
      console.log('ERROR' + err);
    }

    try{
      await this.getDisplayTemplateGroups();
    } catch (err) {
      console.log('ERROR' + err);
    }



    //this.getRegistrations();

    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
    .then(async (sub) => {
      let token = JSON.parse(JSON.stringify(sub));
      (window as any).sub = sub;

      let tokenExist = false;
      if (this.user.pushTokens) {
        tokenExist = this.user.pushTokens.find((token) => token.endpoint === sub.endpoint);
      }

      token.domain = window.location.hostname;
      
      if (!tokenExist) {
        this.apiService.updatePush({email: this.user.email, token: token}).subscribe(
          (response: any) => {
            console.log('Notification Token Saved');
          },
          response => {
            console.log('Notification Token Saved');
          });
      } else
      {
        console.log(`Notification token found: ${JSON.stringify(sub)} `);
      }
    })
    .catch(err => {
      console.log('swPush: catch ' + err);
      console.error("Could not subscribe to notifications", err);
    });


    try{
      await this.getRegistrations();
    } catch (err) {
      console.log('ERROR' + err);
    }

    this.showSpinner = false;
    
  }

  toggleGroup(event, group) {
    if (event.view.getSelection().type !== 'Range') {
      group.value.expanded = !group.value.expanded;
    }
  }

  getLastSeen(time) {
    if (time === undefined || 0) {
      return 'No recent messages';
    }
    // const date = new Date(time.replace(/-/g, '/')).getTime();
    const date = new Date(time).getTime();
    // const now = new Date().getTime() + 2 * 60 * 60 * 1000;
    const now = new Date().getTime();

    const seconds = Math.floor((now - date) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 2) {
      return 'no data';
    }
    if (interval > 1) {
      return interval + ' years ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ' months ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ' days ago';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ' hours ago';
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  }

  previewImage(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    this.imgType = mimeType.replace('image/', '');
    reader.onload = (event) => {
      this.imgURL = reader.result;
    };
  }

  getDevice(deviceId) {
    return new Promise((resolve, reject) => {
      this.apiService.getDevice(deviceId).subscribe(
        (response: any) => {
          if (response) {
            if (response.Count === 1) {
              resolve(response.Items[0]);
            } else {
              alert('Device not found.');
              reject('Device not found.');
            }
          } else {
            alert('Device not found.');
            reject('Device not found.');
          }
        },
        (response: any) => {
          alert('Device not found.');
          reject('Device not found.');
        });
    });
  }

  getDevices(devicesString) {
    return new Promise((resolve, reject) => {
      this.apiService.getDevice(devicesString).subscribe(
        (response: any) => {
          if (response) {
            if (response.Count === 0) {
              alert('Device not found.');
              reject('Device not found.');
            } else {
              resolve(response.Items);
            }
          } else {
            alert('Device not found.');
            reject('Device not found.');
          }
        },
        (response: any) => {
          alert('Device not found.');
          reject('Device not found.');
        });
    });
  }


  uploadImage() {
    const email = this.user.email;
    const imageData = this.imgURL.slice(this.imgURL.indexOf(',') + 1 );
    return new Promise((resolve, reject) => {
      this.apiService.uploadImage(
        {
          type: this.imgType,
          path: `registrations/${email}/${this.selectedDevice.serial}`,
          name: this.selectedDevice.serial,
          image: imageData,
        }).subscribe(
        (response: any) => {
          if (response.url) {
            return resolve(response.url);
          } else {
            alert('image upload failed');
            return reject();
          }
        },
        response => {
          alert('image upload failed');
          return reject();
        });
    });
  }

  async modalNext() {
    if (this.step === 'deviceName') {
      if (this.selectedDevice.name.length === 0 || this.selectedDevice.name === '')  {
        alert('Please input a Name');
        return;
      }
    }

    if (this.step === 'displayTemplateGroups')
    {
        this.displayTemplatesFiltered = [];
        if (this.selectedDisplayTemplateGroupId !== ''){
          this.selectedDisplayTemplateGroup = this.displayTemplateGroups.find(template => template.templateGroupId === this.selectedDisplayTemplateGroupId); 
          for (const i in this.displayTemplates) {
            if (this.selectedDisplayTemplateGroup.displayTemplates[this.displayTemplates[i].templateName]) {
              this.displayTemplatesFiltered.push(this.displayTemplates[i]);
            }
          }
        } else {
          console.log('no filter, showing all templates')
          this.displayTemplatesFiltered = this.displayTemplates;
        }
    }

    if (this.step === 'deviceId') {
      this.showSpinner = true;
      this.selectedDevice.serial = parseInt(this.selectedDevice.serial, 16).toString(16).toUpperCase();
      try {
        await this.getDevice(this.selectedDevice.serial);
        this.selectedDevice.description = this.selectedDevice.serial;
        this.step = this.nextMap[this.step];
      } catch (err) {

      }
      this.showSpinner = false;
    } else {
      
      this.step = this.nextMap[this.step];
      
    }
  }


  selectDisplayTempalateGroup(templateGroupId)
  {
    this.selectedDisplayTemplateGroupId = templateGroupId;
    this.modalNext();

  }

  selectDisplayTemplate(templateName){
    this.selectedDevice.displayTemplate = templateName;
    this.selectedDisplayTemplate = this.displayTemplates.find(template => template.templateName === templateName );

    this.userInputFieldsForm = new FormGroup({});
    if (this.selectedDisplayTemplate.userInputFields){
      for (let field of this.selectedDisplayTemplate.userInputFields) {
        this.userInputFieldsForm.addControl(field.name,new FormControl(field.default,Validators.required))

      }
    }
    this.modalNext();
  }
   
  modalBack() {
    this.step = this.backMap[this.step];
  }

  async addRegistration() {
    this.showSpinner = true;
    const email = this.user.email;
    let imageName;

    if (this.displayTemplates.length > 0 && this.selectedDevice.displayTemplate === '') {
      alert('Please select display template');
    }

    if (this.selectedDevice.name.length === 0 || this.selectedDevice.name === '') {
      alert('Please input a Name');
    }
    this.showSpinner = true;
    if (this.imgURL) {
      try {
        imageName = await this.uploadImage();
      } catch (err) {
        alert(`image uplaod failed: ${err}`);
        return;
      }
    }

    this.selectedDevice.userInputFields = this.userInputFieldsForm.value;
    this.selectedDevice.mqttTopic = this.selectedMqttTopic;

    const now = new Date().getTime();
    const postBody: any = {
      email,
      id: this.selectedDevice.id || `${this.selectedDevice.serial}_${email}_${window.location.hostname}_${now}`,
      origin: `${window.location.hostname}`,
      deleted: false,
      serial: this.selectedDevice.serial,
      deviceId: this.selectedDevice.serial,
      name: this.selectedDevice.name,
      deviceName: this.selectedDevice.name,
      deviceDescription: this.selectedDevice.name,
      description: this.selectedDevice.description,
      displayTemplate: this.selectedDevice.displayTemplate,
      notificationTemplateGroup: this.selectedDevice.notificationTemplateGroup,
      userInputFields : this.selectedDevice.userInputFields,
      mqttTopic: this.selectedDevice.mqttTopic
    }


    if (this.hasGroups && this.selectedDevice.group) {
      postBody.group = this.selectedDevice.group;
    }
    if (imageName) {
      postBody.imagePath = imageName;
    }
    if (postBody.displayTemplate === '') {
      delete postBody.displayTemplate;
    }
    if (postBody.notificationTemplateGroup === '') {
      delete postBody.notificationTemplateGroup;
    }
    postBody.domain = window.location.hostname;

    this.showSpinner = true;
    this.apiService.registerDevice(postBody).subscribe(
      (response: any) => {
        this.showSpinner = false;
        if (response.message === 'success') {
          
          this.apiService.loginRefresh(this.user.email).subscribe(
            (loginResponse: any) => {
              this.userService.setToken(loginResponse.token, this.user.password);
              this.modalRef.close();
              this.getRegistrations();
              this.loadGroups();
              this.showSpinner = false;
            },
            error => {
              this.showSpinner = false;
              alert('Reload failed. Please log in again.' + JSON.stringify(error));
            });

          
        } else {
          this.showSpinner = false;
          alert('Register failed' + JSON.stringify(response,null,2));
        }
      },
      response => {
        this.showSpinner = false;
        alert('Register failed ' + JSON.stringify(response.error.message,null,2));
      });
  }
  
}
