import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from '../../providers/api/api.service';
import { Meta, Title  } from '@angular/platform-browser'; 

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  busyGetConfig = false;
  config = {
    displayTitle: '',
    customer: '',
    displayDescription: '',
    group: false,
    templateCategory: false,
  };

  defaultConfig = {
    displayTitle: 'Title here',
    customer: '',
    displayDescription: 'Description here',
    logo: 'https://proautomation-images-stg.s3.amazonaws.com/images/logo.png',
    group: false,
    templateCategory: false,
  };

  defaultTemplate = {
    photo: 'https://proautomation-images-prd.s3.amazonaws.com/images/pool.jpg',
    name: 'Default',
    description: 'The dafault template',
    templateMeasurement:   [
        {
          graphClasses: [
            'temp_linear_wisol_202004',
            'temp_linear_murata_2018'
          ],
          unit: '°C',
          clickable: true,
          tileClasses: [
            'temp_linear_wisol_202004',
            'temp_linear_murata_2018'
          ],
          name: 'Temperature',
          icon: 'faTemperatureLow',
          tileColor: '#f4f4f4',
          type: 'waterTemp'
        },
        {
          graphClasses: [
            'orp_calibrated_wisol_202004',
            'orp_calibrated_murata_2018'
          ],

          thresholds: [
            {
              name: 'Low',
              description: 'Lower threashold',
              range: {
                max: 649,
                min: 500
              },
              tileColor: '#f3c1c6',
              chart: {
                show: true,
                color: '#f3c1c6'
              }
            },
            {
              name: 'Good',
              description: 'Good threashold',
              range: {
                max: 800,
                min: 650
              },
              tileColor: '#b0e0a8',
              chart: {
                show: true,
                color: '#b0e0a8'
              }
            },
            {
              name: 'High',
              description: 'Lower threashold',
              range: {
                max: 900,
                min: 801
              },
              tileColor: '#f1d1b5',
              chart: {
                show: true,
                color: '#f1d1b5'
              }
            }
          ],
          clickable: true,
          tileClasses: [
            'orp_average_wisol_202004',
            'orp_average_murata_2018'
          ],
          name: 'ORP',
          icon: 'faFlask',
          tileColor: '#f4f4f4',
          type: 'ORP'
        },
        {
          graphClasses: [
            'ph_calibrated_wisol_202004',
            'ph_calibrated_murata_2018'
          ],
          thresholds: [
            {
              name: 'Low',
              description: 'Lower threashold',
              range: {
                max: 6.799,
                min: 6.5
              },
              tileColor: '#f3c1c6',
              chart: {
                show: true,
                color: '#f3c1c6'
              }
            },
            {
              name: 'Good',
              description: 'Good threashold',
              range: {
                max: 7.8,
                min: 6.8
              },
              tileColor: '#b0e0a8',
              chart: {
                show: true,
                color: '#b0e0a8'
              }
            },
            {
              name: 'High',
              description: 'Lower threashold',
              range: {
                max: 8,
                min: 7.80001
              },
              tileColor: '#f1d1b5',
              chart: {
                show: true,
                color: '#f1d1b5'
              }
            }
          ],
          clickable: true,
          tileClasses: [
            'ph_average_wisol_202004',
            'ph_average_murata_2018'
          ],
          name: 'pH',
          icon: 'faEyeDropper',
          tileColor: '#f4f4f4',
          type: 'pH'
        },
        {
          graphClasses: [
            'vcc_raw_wisol_202004',
            'vcc_raw_murata_2018'
          ],
          unit: '%',
          thresholds: [
            {
              name: 'full',
              description: 'full',
              range: {
                max: 100,
                min: 50
              },
              tileColor: '#9cf196',
              chart: {
                show: true,
                color: '#9cf196'
              }
            },
            {
              name: 'half',
              description: 'half',
              range: {
                max: 50,
                min: 30
              },
              tileColor: '#eceba7',
              chart: {
                show: true,
                color: '#eceba7'
              }
            },
            {
              name: 'almost',
              description: 'almost',
              range: {
                max: 30,
                min: 1
              },
              tileColor: '#ebce95',
              chart: {
                show: true,
                color: '#ebce95'
              }
            },
            {
              name: 'dead',
              description: 'dead',
              range: {
                max: 0,
                min: -100
              },
              tileColor: '#edaaaa',
              chart: {
                show: true,
                color: '#edaaaa'
              }
            }
          ],
          clickable: false,
          tileClasses: [
            'vcc_raw_wisol_202004',
            'vcc_raw_murata_2018'
          ],
          name: 'Battery',
          icon: 'faBatteryThreeQuarters',
          tileColor: '#f4f4f4',
          type: 'batPercentage'
        }
      ]
  };

  configEmmiter;

  constructor(  private apiService: ApiService,
                private meta: Meta,
                private titleService: Title
    ) {
    this.configEmmiter = new EventEmitter();
    this.getConfig();
  }

  public getConfigChangeEmitter() {
    return this.configEmmiter;
    
  }

  public getConfig() {

    if (!this.busyGetConfig) {

      this.busyGetConfig = true;

      const configLocalString = localStorage.getItem('domainConfig')
      if (configLocalString){
        console.log('Local Site Config Found');
        this.setConfig(JSON.parse(configLocalString));
        this.busyGetConfig = false;

      } else {
        const domain = window.location.hostname;
        console.log('Get site config from cloud');
        this.apiService.getSiteConfig(domain).subscribe(
        (response: any) => {
          if (response && response.Count === 1) {
            this.setConfig(response.Items[0]);
            this.busyGetConfig = false;
          } else {
            this.setConfig(this.defaultConfig);
            this.busyGetConfig = false;
          }
        },
        response => {
          this.setConfig(this.defaultConfig);
          console.log('No config found. Use default config');
          this.busyGetConfig = false;
        });
      }
    }
    

    return this.config;
  }
  public setConfig(config) {
    this.config = config;
    localStorage.setItem('domainConfig',JSON.stringify(config));
    this.meta.updateTag(  
      { name: 'apple-mobile-web-app-title', content: this.config.displayTitle },  
      'name=apple-mobile-web-app-title'  
    )  
    this.titleService.setTitle(this.config.displayTitle);
    this.configEmmiter.emit(this.config);
  }

  public clearConfig() {
    localStorage.removeItem('domainConfig');
  }

  public getDefaultTemplate() {
    return this.defaultTemplate;
  }
  public setDefaultTemplate(defaultTemplate) {
    this.defaultTemplate = defaultTemplate;
  }

}
