<app-spinner [show]="showSpinner" ></app-spinner>

<h4>User info for {{ username }}</h4>

<div *ngIf="!updating" class="propertyContainer">
  <span class="propertyName">Max Devices: </span> <span class="propertyValue"> {{ userInfo.maxNumberOfDevices }}</span>
</div>


<h4>Registrations:</h4>
<table class="table table-striped table-hover" hover="true">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Device ID</th>
      <th scope="col">Serial</th>
      <th scope="col">Group</th>
      <th scope="col">Domain</th>
      <th scope="col">Name</th>
      <th scope="col">Description</th>
      <th scope="col">Display Template</th>
      <th scope="col">Notification Template</th>
      <th scope="col">Registration Date</th>
      <th scope="col">Last update Date</th>
      <th scope="col">Deleted</th>


    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let registration of deviceRegistrations; index as i" >
      <th scope="row">{{ i + 1 }}</th>
      <td>{{ registration.deviceId }}</td>
      <td>{{ registration.serial }}</td>
      <td>{{ registration.group }}</td>
      <td>{{ registration.domain }}</td>
      <td>{{ registration.name }}</td>
      <td>{{ registration.description }}</td>
      <td>{{ registration.displayTemplate }}</td>
      <td>{{ registration.notificationTemplateGroupName }}</td>
      <td>{{ getPrettyDate(registration.created_at) }}</td>
      <td>{{ getPrettyDate(registration.updated_at) }}</td>
      <td>{{ registration.deleted }}</td>
 
      
      
    </tr>
    </tbody>
  </table>


  <h4>Push registration (notification) tokens:</h4>
<table class="table table-striped table-hover" hover="true">
    <thead>
    <tr>
      
      <th scope="col">#</th>
      <th scope="col">Refresh</th>
      <th scope="col">Remove</th>
      <th scope="col">Domain</th>
      <th scope="col">Endpoint</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let token of pushTokens; index as i" >
      <th scope="row">{{ i + 1 }}</th>
      <td> <button  *ngIf="!updating" (click)="refreshPushToken(i)">Refresh Token</button></td>
      <td> <button  *ngIf="!updating" (click)="removePushToken(i)">Remove Token</button></td>
      
      <td>{{ token.domain }}</td>
      <td>{{ token.endpoint }}</td>

      
      
    </tr>
    </tbody>
  </table>

  <h4>User Roles:</h4>
  <table class="table table-striped table-hover" hover="true">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Role</th>
 
  
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let role of roles; index as i" >
        <th scope="row">{{ i + 1 }}</th>
        <td>{{ role }}</td>
        
      </tr>
      </tbody>
    </table>
