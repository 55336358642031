import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from '../pages/login/login.component';
import { HomeComponent } from '../pages/home/home.component';

// import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { NgChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminComponent } from '../pages/admin/admin.component';
import { HeaderComponent } from '../components/header/header.component';
import { CustomersComponent } from '../pages/admin_pages/customers/customers.component';
import { DisplayTemplatesComponent } from '../pages/admin_pages/display-templates/display-templates.component';
import { UsersComponent } from '../pages/admin_pages/users/users.component';
import { DevicesComponent } from '../pages/admin_pages/devices/devices.component';
import { DeviceComponent } from '../components/device/device.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeviceEditComponent } from '../pages/admin_pages/device-edit/device-edit.component';
import { NotificationTemplatesComponent } from '../pages/admin_pages/notification-templates/notification-templates.component';
import { NotificationTemplateGroupsComponent } from '../pages/admin_pages/notification-template-groups/notification-template-groups.component';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UserEditComponent } from '../pages/admin_pages/user-edit/user-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DataClassComponent } from '../pages/admin_pages/data-class/data-class.component';
import { DisplayTemplateGroupsComponent } from '../pages/admin_pages/display-template-groups/display-template-groups.component'


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        SpinnerComponent,
        AdminComponent,
        HeaderComponent,
        CustomersComponent,
        DisplayTemplatesComponent,
        UsersComponent,
        DevicesComponent,
        DeviceComponent,
        DeviceEditComponent,
        NotificationTemplatesComponent,
        NotificationTemplateGroupsComponent,
        UserEditComponent,
        DataClassComponent,
        DisplayTemplateGroupsComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        // SlimLoadingBarModule,
        FontAwesomeModule,
        HttpClientModule,
        NgChartsModule,
        NgbModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ReactiveFormsModule,
    ],
    // providers: [],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(update: SwUpdate ) {
    update.available.subscribe(update => {
      console.log('Update Avaialble');
    });



    
  }
 }
