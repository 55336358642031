import { Component } from '@angular/core';
import { ApiService } from '../providers/api/api.service';
import { ConfigService } from '../providers/config/config.service';

import {
        NavigationCancel,
        Event,
        NavigationEnd,
        NavigationError,
        NavigationStart,
        Router,
       } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'ProAutomation Sense';

  constructor(

    // private loadingBar: SlimLoadingBarService,
    private router: Router,
    private apiService: ApiService,
    private configService: ConfigService,
    ) {
    (window as any).pathParams = window.location.pathname;
    (window as any).pathHash = window.location.hash;
    this.printChangeLog();

    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }


  printChangeLog(){
    (window as any).version = 'v1.5.3';
    console.log('Current Version:' + (window as any).version);
    console.log('CHANGELOG');
    console.log('v.1.5.3: chart style fixes');
    console.log('v.1.5.2: day week month and graph fixes');
    console.log('v.1.5.1: Style related updates');
    console.log('v.1.5.0: Upgrade to angular 13 to allow for better notifications');
    console.log('v.1.4.28: fix "last seen"');
    console.log('v.1.4.27: Body custom Header and footer setup');
    console.log('v.1.4.26: Locales setup');
    console.log('v.1.4.25: Apple related and page title updates');
    console.log('v.1.4.24: show token data');
    console.log('v.1.4.23: show display and notification templates, in user admin');
    console.log('v.1.4.22: Added function to refresh push token with domain , and generate alert.');
    console.log('v.1.4.21: Added serial and last modified for registrations under user.');
    console.log('v.1.4.20: Leading zero fix');
    console.log('v.1.4.19: User input in display variables, and mqtt via registration');
    console.log('v.1.4.18: Display template styles');
    console.log('v.1.4.17: Add display template groups');
    console.log('v.1.4.16: Allow for background image');
    console.log('v.1.4.15: Handle multi point calibration.');
    console.log('v.1.4.14: auto enable streams, when device is set to active.');
    console.log('v.1.4.13: fix bugs on device page, make sigfox downlink the source of truth');
    console.log('v.1.4.12: compensate for time zone during calibration');
    console.log('v.1.4.11: added reload after streams refresh');
    console.log('v.1.4.10: Added calibration functions and basic data class viewer / editor');
    console.log('v.1.4.9: fixed logout bug');
    console.log('v.1.4.8: Added ability to add mqtt topics based on user settings');
    console.log('v.1.4.7: Added user edit functions, can now remove push tokens');
    console.log('v.1.4.6: Added user edit functions, only view registrations');
    console.log('v.1.4.5: Auto Login added, Quick device config button added to home screen modals, calibration sync button');
    console.log('v.1.4.4: Basic device management functions added.');
    console.log('v.1.4.3: Changes for domain styling.');
    console.log('v.1.4.2: use poolsense device registrations.');
    console.log('v.1.4.1: use poolsense devices.');
    console.log('v.1.4.0: use poolsense users.');
    console.log('v.1.3.1: readd x-token.');
    console.log('v.1.3.0: Add X-API-KEY.');
    console.log('v.1.2.16: Make comment an array.');
    console.log('v.1.2.15: Add account activation');
    console.log('v.1.2.14: Add userCanRegister flag for login page per domain');
    console.log('v.1.2.13: Add admin email functionality(see admin users registrations on domain)');
    console.log('v.1.2.12: Fix loading issue on groups for gauges.');
    console.log('v.1.2.11: Stop spinner on loading error.');
    console.log('v.1.2.10: Handle error on customers and template pages.');
    console.log('v.1.2.9: Fix device page to fetch non registered devices with error.');
    console.log('v.1.2.8: use ? as pw links');
    console.log('v.1.2.7: s3 blocks pw link. update to hash');
    console.log('v.1.2.6: Fix linking for pw reset link');
    console.log('v.1.2.5: Add password reset.');
    console.log('v.1.2.4: Add delete.');
    console.log('v.1.2.3: Add domain to registrations.');
    console.log('v.1.2.2: Fix Gauge group.');
    console.log('v.1.2.1: Add Gauge.');
    console.log('v.1.2.0: Graph only load on click. Updateable tx on device page.');
    console.log('v.1.1.1: Bump.');
    console.log('v.1.1.0: Add service worker and push.');
    console.log('v.1.0.6: Endpoint fix.');
    console.log('v.1.0.5: Fix registration notification save issue.');
    console.log('v.1.0.4: Fix empty notification state issue.');
    console.log('v.1.0.3: Use correct api.');
    console.log('v.1.0.2: Add notification setup.');
    console.log('v.1.0.1: Add data reload every 3 minutes.');
    console.log('v.1.0.0: Initial commit.');
  }
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      // this.loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      // this.loadingBar.complete();
    }
    if (event instanceof NavigationCancel) {
      // this.loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      // this.loadingBar.stop();
    }
  }
}
